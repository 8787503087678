<template>
  <div v-if="loading" style="z-index: 999;" class="cloning-loader tw-fixed tw-top-0 tw-left-0 tw-w-full tw-h-full tw-bg-black tw-z-20 tw-bg-opacity-70 tw-flex tw-flex-col tw-items-center tw-justify-center">
    <Loader class="estimate__loader main tw-text-white" customClass="big tw-text-white">
    </Loader>
  </div>
  <div>
    <AddedAccessoriesList :area="area" @hideButtons="$emit('hideButtons', true)" :items="addedAccessories" @add="addAccessory"
      @delete="(index) => deleteAccessory(index)" @edit="(index) => edit(index)"
      v-if="!showAddNewAccessories && !isAccessorySelectionForEntireOrder" />

    <div v-else class="accessories_select">
      <div class="editor-title">
        <div class="top_item">
          <div @click="closeEditor">
            <svg-icon name="arrow-left" />
            &nbsp;
            To Accessories list
          </div>
          <div class="item_sub_title">
            {{ selectedItemName }}
          </div>
          <div class="sub_title" v-if="currentPrice">
            Price: {{ formatter.formatMoney(currentPrice) }}
          </div>
        </div>
      </div>
      <div class="left" v-if="Object.keys(itemSelected).length === 0">
        <div class="column left_items" v-if="Object.keys(itemSelected).length === 0">

          <div class="other_items">
            <div class="item_main_div">
              <div class="sub_title" v-if="hardwares.length > 0">
                Hardware:
              </div>
              <div v-for="(item, index) in hardwares" :key="index"
                :class="{ 'selected_item': selectedItemFromLeftSide?.id === item.id && selectedItemFromLeftSide?.product_type_name === 'Hardware' }"
                class="item_sub_title" @click="leftSideListItemClicked(item, true)">
                <svg-icon
                  v-if="selectedItemFromLeftSide?.id === item.id && selectedItemFromLeftSide?.product_type_name === 'Hardware'"
                  name="solid/check-circle" />
                {{ item.name }} ({{ item.item_matrices_count }})
              </div>
              <div class="sub_title" v-if="lengthOrSheets.length > 0">
                Lengths / Sheet:
              </div>
              <div v-for="(item, index) in lengthOrSheets" :key="index"
                :class="{ 'selected_item': selectedItemFromLeftSide?.id === item.id && selectedItemFromLeftSide?.product_type_name === 'Lengths / Sheet' }"
                class="item_sub_title" @click="leftSideListItemClicked(item, true)">
                <svg-icon
                  v-if="selectedItemFromLeftSide?.id === item.id && selectedItemFromLeftSide?.product_type_name === 'Lengths / Sheet'"
                  name="solid/check-circle" />
                {{ item.name }} ({{ item.item_matrices_count }})
              </div>

            </div>
          </div>
        </div>

      </div>

      <div class="right">
        <div class="wizard__btn_back" v-if="Object.keys(itemSelected).length !== 0" @click="back()">
          <svg-icon name="arrow-left" />&nbsp;
          To category selection
        </div>

        <div v-if="Object.keys(itemSelected).length === 0">

          <div class="sub_title">Select Accessory</div>
          <input v-model="search" class="input" placeholder="Start typing to search" type="search"
            @update:modelValue="searchItems">

          <div v-if="rightSideList.length === 0 && !search" class="no_data">
            Start typing to search for item or select category from
            <br>Hardware or Lengths / Sheets lists
          </div>
          <div v-else-if="rightSideList.length === 0 && search" class="no_data">
            Nothing found
          </div>
          <div v-else class="accessory_list">

            <li v-for="(item, index) in itemList"
              @click="itemSelected = item, getLengthTypes(), itemSelected.qty = 1, setDefaultValues()" :key="index">
              {{ item.name }}
            </li>

          </div>

        </div>

        <div v-else>

          <div class="accessories pt-4">

            <div class="tw-flex tw-flex-col tw-gap-4 md:tw-flex-row">

              <div class=" qty_lable_column">
                <p>{{ input.label }}</p>
                <!-- <small class="small">&nbsp;</small> -->
              </div>
              <div class="">
                <InputGroup :data="input.input" v-model="itemSelected.qty" />
              </div>
              <div class="">
                <!-- add itemSelected.colour -->
                <SelectColor v-if="itemSelected.colour" :showLabelAtLeft="true" :colour="itemSelected.colour?.id"
                  :colourGroup="itemSelected.colour_group" :sameAsFrame="itemSelected.colour?.is_same_as_frame ?? itemSelected.is_same_as_frame"
                             :frameColour="frame_colour_id" :containerId="itemSelected.container_id"
                             :itemId="itemSelected.id"
                             @submit="selectColor" />
              </div>

            </div>


          </div>

          <div class="accessories" v-if="itemSelected.measure_type === 'LNTH'">

            <div class="row" :class="{ 'row_spacing': isAccessorySelectionForEntireOrder }"
              v-for="(data, index) in lengthInputs" :key="index">

              <div class="column" v-if="isAccessorySelectionForEntireOrder">
                <Checkbox class="item__checkbox" :data="{ key: `checkbox-${index}` }"
                  v-model="itemSelected[`is_checkbox_of_${data.model}_selected`]"
                  @update:modelValue="checkBoxClicked(index)" />
              </div>
              <div class="column length_input_lable">
                <p>{{ data.label }}</p>
                <small v-if="showLengthInputs" class="small">{{ data.smallText }}</small>
              </div>

              <div class="column" v-if="!isAccessorySelectionForEntireOrder">
                <SelectDropdown :style="`zIndex: ${lengthInputs.length-index}`" :list="data.dropdown.list" :small="true" placeholder="Select"
                  :selected="data.dropdown.selected" @select="(val) => selected(index, val)" />
              </div>
              <div class="column">
                <InputGroup @change="validateItem(false)" @keyup="validateItem(false)" :data="data.input"
                  v-model="itemSelected[data.model]" :classes="'input_flex'">
                </InputGroup>
              </div>
            </div>
          </div>
          <div class="accessories" v-else-if="itemSelected.measure_type === 'SQR'">

            <template v-if="firstDimension === 'width'">
              <div class="row" :class="{ 'row_spacing': isAccessorySelectionForEntireOrder }">

                <div class="column length_input_lable">
                  <p>Width</p>
                </div>

                <div class="column" v-if="!isAccessorySelectionForEntireOrder">
                  <SelectDropdown :list="lengthInputs[0].dropdown.list" :small="true" placeholder="Select"
                    :selected="lengthInputs[0].dropdown.selected" @select="(val) => selected(0, val)" />
                </div>
                <div class="column">
                  <Field 
                    :key="itemSelected?.id"
                    name="Width" 
                    v-model="itemSelected[lengthInputs[0].model]" 
                    v-slot="{ handleChange, value, errors }"   
                    :rules="{required: true, numeric: true, max_value: itemSelected?.limits?.max_width, min_value: itemSelected?.limits?.min_width }"
                  >
                    <InputGroup 
                      @change="validateItem(false, errors[0])" 
                      @keyup="validateItem(false, errors[0])" 
                      :data="lengthInputs[0].input"
                      :classes="'input_flex'"
                      :modelValue="value"
                      @update:modelValue="handleChange"
                    />
                    <span class="tw-text-xs tw-text-red-500">{{errors[0]}}</span>
                  </Field>
                </div>
              </div>

              <div class="row" :class="{ 'row_spacing': isAccessorySelectionForEntireOrder }">

                <div class="column length_input_lable">
                  <p>Drop</p>
                </div>

                <div class="column" v-if="!isAccessorySelectionForEntireOrder">
                  <SelectDropdown :list="lengthInputs[1].dropdown.list" :small="true" placeholder="Select"
                    :selected="lengthInputs[1].dropdown.selected" @select="(val) => selected(1, val)" />
                </div>
                <div class="column">
                  <Field 
                    name="Drop" 
                    v-model="itemSelected[lengthInputs[1].model]" 
                    v-slot="{ handleChange, value, errors  }"   
                    :rules="{required: true, numeric: true, max_value: itemSelected?.limits?.max_length, min_value: itemSelected?.limits?.min_length }"
                  >
                    <InputGroup 
                      @change="validateItem(false)" 
                      @keyup="validateItem(false)" 
                      :data="lengthInputs[1].input"
                      :classes="'input_flex'"
                      :modelValue="value"
                      @update:modelValue="handleChange"
                    />
                    <span class="tw-text-xs tw-text-red-500">{{errors[0]}}</span>
                  </Field>
                </div>
              </div>
            </template>

            <template v-else>
              <div class="row" :class="{ 'row_spacing': isAccessorySelectionForEntireOrder }">

                <div class="column length_input_lable">
                  <p>Drop</p>
                </div>

                <div class="column" v-if="!isAccessorySelectionForEntireOrder">
                  <SelectDropdown :list="lengthInputs[1].dropdown.list" :small="true" placeholder="Select"
                    :selected="lengthInputs[1].dropdown.selected" @select="(val) => selected(1, val)" />
                </div>
                <div class="column">
                  <Field 
                    name="Drop" 
                    v-model="itemSelected[lengthInputs[1].model]" 
                    v-slot="{ handleChange, value, errors  }"   
                    :rules="{required: true, numeric: true, max_value: itemSelected?.limits?.max_length, min_value: itemSelected?.limits?.min_length }"
                  >
                    <InputGroup 
                      @change="validateItem(false)" 
                      @keyup="validateItem(false)" 
                      :data="lengthInputs[1].input"
                      :classes="'input_flex'"
                      :modelValue="value"
                      @update:modelValue="handleChange"
                    />
                    <span class="tw-text-xs tw-text-red-500">{{errors[0]}}</span>
                  </Field>
                </div>
              </div>
              <div class="row" :class="{ 'row_spacing': isAccessorySelectionForEntireOrder }">

                <div class="column length_input_lable">
                  <p>Width</p>
                </div>

                <div class="column" v-if="!isAccessorySelectionForEntireOrder">
                  <SelectDropdown :list="lengthInputs[0].dropdown.list" :small="true" placeholder="Select"
                    :selected="lengthInputs[0].dropdown.selected" @select="(val) => selected(0, val)" />
                </div>
                <div class="column">
                  <Field 
                    name="Width" 
                    v-model="itemSelected[lengthInputs[0].model]" 
                    v-slot="{ handleChange, value, errors  }"   
                    :rules="{required: true, numeric: true, max_value: itemSelected?.limits?.max_width, min_value: itemSelected?.limits?.min_width }"
                  >
                    <InputGroup 
                      @change="validateItem(false)" 
                      @keyup="validateItem(false)" 
                      :data="lengthInputs[0].input"
                      :classes="'input_flex'"
                      :modelValue="value"
                      @update:modelValue="handleChange"
                    />
                    <span class="tw-text-xs tw-text-red-500">{{errors[0]}}</span>
                  </Field>
                </div>
              </div>
            </template>

          </div>
          <div v-if="errorMessage" class="validation_text">
            <span>
              * {{ errorMessage }}
            </span>

          </div>

          <div v-if="!isAccessorySelectionForEntireOrder">
            <Teleport to="#panel-btn-teleport">
              <button class="btn btn_transparent tw-py-1.5 tw-mr-2.5" @click="addOrUpdate">{{ editingIndex != null ? 'update' : 'add' }}</button>
            </Teleport>

            <Teleport to="#panel-btn-teleport-mobile">
              <button class="btn btn_transparent tw-py-1.5 tw-mr-2.5" @click="addOrUpdate">{{ editingIndex != null ? 'update' : 'add' }}</button>
            </Teleport>

          </div>

        </div>

      </div>
    </div>

  </div>
</template>

<script>
import api from '../../../services/wizard.service'
import _ from 'lodash';
import { computed, reactive, ref, watch, onBeforeUnmount } from 'vue'
import InputGroup from '@/components/item-wizard/components/form/InputGroup.vue'
import SelectColor from '../../elements/SelectColor.vue'
import SelectDropdown from '@/components/item-wizard/components/elements/SelectDropdown.vue'
import AddedAccessoriesList from './accessories/AddedAccessoriesList'
import { useOptionLogic } from "../../../composables/estimate/create"
import Checkbox from '../../form/Checkbox.vue'
import { useFormatter } from "@/components/item-wizard/composables/formatter";
import { isOpeningIsThePreferToEnter, isDropIsTheSizeToEnter, firstDimensionPreference, sizeEnteringPreference } from '../../../helpers/portalSpecifications';
import Loader from '../../elements/Loader.vue';

export default {
  props: ['items', 'currentSection', 'allData', 'currentSettings', 'selection', 'isAccessorySelectionForEntireOrder', 'area'],
  name: "accessories",
  components: {
    InputGroup,
    SelectColor,
    SelectDropdown,
    AddedAccessoriesList,
    Checkbox,
    Loader
  },
  setup(props, { emit }) {
    const formatter = useFormatter()

    const editItem = ref(null)
    const firstDimension = ref(props.isQuoting ? 'width' : firstDimensionPreference())

    const rightSideSelectedBySystem = ref(false)
    const addedAccessories = ref(props.selection[props.currentSection]?.options['ACC'].option_settings ?? [])
    const key = ref(0)
    const toggleHardwareList = ref(false)
    const toggleLengthList = ref(true)
    const initialData = ref([])
    const selectedItemFromLeftSide = ref(null)
    const search = ref(null)
    const rightSideList = ref([])
    const showAddNewAccessories = ref(false)
    const itemSelected = ref({})
    const allowedAccessoriesForLengthInputsSection = ref(['Build Out Frames', 'Door Sections', 'Window Sections', 'Fire Escape Section', 'Dual Slide Sections'])
    const lengthTypes = ref([])
    const lengthTypesWithKeys = ref([])
    const sizeStep = ref(props.allData.steps.find((step) => step.name === "Size")?.data)
    const positionSizes = ref({
      overall_drop: sizeStep.value?.overall_drop,
      overall_width: sizeStep.value?.overall_width,
      perimeter: (sizeStep.value?.overall_width * 2) + (sizeStep.value?.overall_drop * 2)
    })
    const optionLogic = useOptionLogic(props)
    const frame_colour_id = ref(props.allData.steps.find(step => step.name === "Frame Colour")?.data_id)
    const editingIndex = ref(null)
    const limits = ref({})
    const lengths = ref(['length1', 'length2', 'length3', 'length4'])
    const errorMessage = ref(null)
    const currentPrice = ref(null)

    const allStyles = ref([])

    const styleList = computed(() => {
      if (!search.value)
        return allStyles.value

      return allStyles.value.filter(s => {
        return rightSideList.value.filter(i => {
          return i.style_id == s.id
        }).length > 0
      })
    })

    const addOnce = function() {
      console.log('once...............>>>>')
    }

    onBeforeUnmount(() => {
      emit('hideButtons', false)
    })

    const loading = ref(false)

    const itemList = computed(() => {

      if (selectedItemFromLeftSide.value == {} || selectedItemFromLeftSide.value == null)
        return rightSideList.value

      return rightSideList.value.filter(i => {
        return i.style_id == selectedItemFromLeftSide.value.id
      })

    })

    const hardwares = computed(() => {
      return _.filter(styleList.value, { product_type_name: 'Hardware' })
    })

    const lengthOrSheets = computed(() => {
      return _.filter(styleList.value, { product_type_name: 'Lengths / Sheet' })
    })

    async function getTypeAndStyles() {
      if (rightSideSelectedBySystem.value) {
        rightSideSelectedBySystem.value = false
        selectedItemFromLeftSide.value = {}
      }
      allStyles.value = (await api.getTypeAndStyles(search.value ?? '')).data
    }

    async function getListItems() {
      if ((selectedItemFromLeftSide.value == null || selectedItemFromLeftSide.value == {}) && !search.value) {
        rightSideList.value = []
        return
      }


      rightSideList.value = (await api.getListItems(search.value ?? '', selectedItemFromLeftSide.value?.id ?? '', selectedItemFromLeftSide.value?.product_type_id ?? '')).data
    }

    const getLimitsAndColours = async () => {
      if (!itemSelected.value.id) return
      const has_same_as_frame = (itemSelected.value.hasOwnProperty('colour') && itemSelected.value.colour?.hasOwnProperty('is_same_as_frame')) || itemSelected.value.hasOwnProperty('is_same_as_frame')
      const data = (await api.getLimitsAndColours(itemSelected.value.id, itemSelected.value?.colour?.id ?? frame_colour_id.value))
      data.colour.is_same_as_frame = has_same_as_frame ? (itemSelected.value.is_same_as_frame ?? itemSelected.value.colour?.is_same_as_frame) : data.is_same_as_frame
      itemSelected.value.colour = data.colour
      itemSelected.value.limits = data.limits
      itemSelected.value.is_same_as_frame = data.colour.is_same_as_frame
      limits.value = data.limits
    }

    async function getPriceOfSelectedAccessories() {
      const data = (await api.getPriceOfSelectedAccessories({
        items: addedAccessories.value,
        frame_colour_id: frame_colour_id.value,
        position_id: props.allData?.position_id
      })).data
      addedAccessories.value = data.items

    }

    const getLengthTypes = async () => {
      validateItem(true)
      loading.value = true
      if (lengthTypes.value.length === 0) {
        const data = (await api.getLengthTypes()).data
        lengthTypesWithKeys.value = data
        lengthTypes.value = data.map(x => x.name)
      }
      await getLimitsAndColours()
      loading.value = false
    }

    getTypeAndStyles()



    function leftSideListItemClicked(item, reset = false) {
      if (!selectedItemFromLeftSide.value) {
        selectedItemFromLeftSide.value = item
      } else if (selectedItemFromLeftSide.value && selectedItemFromLeftSide.value.id === item.id && selectedItemFromLeftSide.value.product_type_name === item.product_type_name) {
        selectedItemFromLeftSide.value = null
      } else {
        selectedItemFromLeftSide.value = null
        selectedItemFromLeftSide.value = item
      }
      if (reset) itemSelected.value = {}
      /*if (search.value && search.value.length >= 3)*/ getListItems()
    }

    getData()
    function getData() {
      addedAccessories.value = (props.selection[props.currentSection]?.options['ACC'].option_settings ?? [])
      closeEditor()
    }

    const searchItems = _.debounce(async () => {
      if (search.value?.length >= 3 || !search.value) {
        await getListItems()
        //await getTypeAndStyles()
      }
    }, 300);

    const initialState = {
      input: {
        label: 'Quantity',
        input: {
          placeholder: 'Enter quantity',
          disabled: false,
          type: 'number',
          required: true,
          error: '',
          min: '1'
        },
      },
      lengthInputs: [
        {
          model: 'length1',
          label: 'Length 1 ',
          smallText: '(Drop left)',
          code: 'HTL',
          input: {
            label: '',
            placeholder: '',
            disabled: true,
            type: 'number',
            required: false,
            error: '',
            min: '0',
            suf: 'mm'
          },
          dropdown: {
            list: [],
            selected: 'None'
          }
        },
        {
          model: 'length2',
          label: 'Length 2 ',
          smallText: '(Width Top)',
          code: 'WDT',
          input: {
            label: '',
            placeholder: '',
            disabled: true,
            type: 'number',
            required: false,
            error: '',
            min: '0',
            suf: 'mm'
          },
          dropdown: {
            list: [],
            selected: 'None'
          }
        },
        {
          model: 'length3',
          label: 'Length 3 ',
          smallText: '(Drop Right)',
          code: 'HTR',
          input: {
            label: '',
            placeholder: '',
            disabled: true,
            type: 'number',
            required: false,
            error: '',
            min: '0',
            suf: 'mm'
          },
          dropdown: {
            list: [],
            selected: 'None'
          }
        },
        {
          model: 'length4',
          label: 'Length 4 ',
          smallText: '(Width Bottom)',
          code: 'WDB',
          input: {
            label: '',
            placeholder: '',
            disabled: true,
            type: 'number',
            required: false,
            error: '',
            min: '0',
            suf: 'mm'
          },
          dropdown: {
            list: [],
            selected: 'None'
          }
        }
      ]
    }

    const input = reactive({ ...initialState.input })

    const lengthInputs = ref(JSON.parse(JSON.stringify(initialState.lengthInputs)))

    function resetForm() {
      Object.assign(input, initialState.input)
      lengthInputs.value = JSON.parse(JSON.stringify(initialState.lengthInputs))
      lengthTypes.value = []
    }

    const showSelectedItemForm = computed(() => {
      return _.isEmpty(itemSelected.value)
    })

    const setDefaultValues = async () => {
      if (props.isAccessorySelectionForEntireOrder) {

        lengthInputs.value.forEach((input, key) => {
          itemSelected.value[input.model] = 0
          itemSelected.value[`is_checkbox_of_${input.model}_selected`] = (itemSelected.value.measure_type === 'SQR' && key < 2)
          input.input.disabled = !itemSelected.value[`is_checkbox_of_${input.model}_selected`]
        })
      }
      selectLeftSideAutomatically()

    }

    const selectLeftSideAutomatically = () => {
      //If related left side menu is not in expanded state, expand it
      const isSelectedWasAHardware = hardwares.value.find(hardware => hardware.id === itemSelected.value.style_id)

      toggleHardwareList.value = !isSelectedWasAHardware
      toggleLengthList.value = isSelectedWasAHardware
      key.value++

      if (!selectedItemFromLeftSide.value?.name && itemSelected.value.name) {
        rightSideSelectedBySystem.value = true
        leftSideListItemClicked(styleList.value.find(x => x.id === itemSelected.value.style_id))
      }
    }

    const showLengthInputs = computed(() => {
      return !props.isAccessorySelectionForEntireOrder && allowedAccessoriesForLengthInputsSection.value.includes(selectedItemFromLeftSide.value?.name)
      //allowedAccessoriesForLengthInputsSection.value.includes(selectedItemFromLeftSide.value?.name)
    })

    const selected = async (index, value, updateOnlySelect = false) => {
      validateItem(true)

      lengthInputs.value[index].dropdown.selected = value
      lengthInputs.value[index].input.disabled = value === "None"
      itemSelected.value[`${lengthInputs.value[index].model}_type`] = lengthTypesWithKeys.value.find(type => type.name === value)?.code

      if (updateOnlySelect) return

      if (['Height Left', 'Height Middle', 'Height Right'].includes(value)) {
        itemSelected.value[lengthInputs.value[index].model] = props.items.sections[props.currentSection].drop
      } else if (['Width Bottom', 'Width Middle', 'Width Top'].includes(value)) {
        itemSelected.value[lengthInputs.value[index].model] = props.items.sections[props.currentSection].width
      } else if (['Perimeter'].includes(value)) {
        itemSelected.value[lengthInputs.value[index].model] = props.items.sections[props.currentSection].drop * 2 +
          props.items.sections[props.currentSection].width * 2
      } else {
        itemSelected.value[lengthInputs.value[index].model] = ''
      }

    }

    const checkBoxClicked = async (index) => {
      lengthInputs.value[index].input.disabled = !lengthInputs.value[index].input.disabled
      itemSelected.value[`is_checkbox_of_${lengthInputs.value[index].model}_selected`] = !lengthInputs.value[index].input.disabled

      validateItem(true)

    }

    const selectColor = async (colour) => {
      itemSelected.value.colour = colour
      itemSelected.value.is_same_as_frame = colour.is_same_as_frame
    }

    watch(lengthTypes, (value) => {
      lengthInputs.value.forEach((input, index) => {
        if (!showLengthInputs.value) {
          input.dropdown.list = value
        } else {
          lengthInputs.value[index].dropdown.list = lengthTypesWithKeys.value.filter(i => { return ['NONE', input.code, 'CUST'].includes(i.code) }).map(i => i.name)
        }
      })
    })

    watch(itemSelected, (value) => {
      if (props.isAccessorySelectionForEntireOrder) {
        emit('hideButtons', Object.keys(value).length === 0)
      }
    })

    watch(itemSelected, (itm) => {
      if (itm && itm.name && !selectedItemFromLeftSide.value?.name) {
        selectLeftSideAutomatically()
      }
    }, {immediate: true})

    const back = async () => {
      if (rightSideSelectedBySystem.value) {
        rightSideSelectedBySystem.value = false
        selectedItemFromLeftSide.value = {}
      }
      if (editingIndex.value) {
        showAddNewAccessories.value = false
        return
      }
      if (itemSelected.value.qty) itemSelected.value = {}
      else if (showAddNewAccessories.value) {
        showAddNewAccessories.value = false
        emit('hideButtons', false)
        search.value = null
        searchItems()
      }

      getListItems()
    }

    const addOrUpdate = async () => {
      validateItem()
      if (errorMessage.value) return

      itemSelected.value.item = itemSelected.value.id ?? itemSelected.value.item

      lengthInputs.value.forEach((input, index) => {
        if (!itemSelected.value[`${input.model}_type`]) {
          itemSelected.value[`${input.model}_type`] = "NONE"
        }
      })

      if (editingIndex.value != null) addedAccessories.value[editingIndex.value] = itemSelected.value
      else addedAccessories.value.push(itemSelected.value)
      props.selection[props.currentSection].options['ACC'].option_settings = addedAccessories.value.length > 0 ? addedAccessories.value : null
      emit('updateOptionColour')

      // await getPriceOfSelectedAccessories()
      itemSelected.value = {}
      search.value = null
      resetForm()
      showAddNewAccessories.value = false
      emit('hideButtons', false)
      editingIndex.value = null
      // props.selection[props.currentSection].options['ACC'].option_settings = addedAccessories.value.length > 0 ? addedAccessories.value : null
      updatedPrice()


      // if (editingIndex.value != null) addedAccessories.value[editingIndex.value] = itemSelected.value
      // else addedAccessories.value.push(itemSelected.value)
      // await getPriceOfSelectedAccessories()
      // itemSelected.value = {}
      // search.value = null
      // resetForm()
      // showAddNewAccessories.value = false
      // emit('hideButtons', false)
      // editingIndex.value = null
      // props.selection[props.currentSection].options['ACC'].option_settings = addedAccessories.value.length > 0 ? addedAccessories.value : null
      // updatedPrice()

    }

    const validateItem = (reset = false, msg = null) => {

      const maxLength = limits.value.max_length
      const minLength = limits.value.min_length
      const maxWidth = limits.value.max_width
      const minWidth = limits.value.min_width
      let hasErrors = false
      let values = []

      if(msg) {
        errorMessage.value = msg
        return
      }
      errorMessage.value = null

      if (reset) {
        errorMessage.value = null
        return
      }

      if(itemSelected.value.qty <= 0) {
        errorMessage.value = 'Quantity is required.'
        return
      }

      for (const length of lengths.value) {
        const value = itemSelected.value[length]
        values.push(value)
        hasErrors = maxLength > 0 && minLength > 0 && value && (value > maxLength || value < minLength)

        if (!hasErrors && props.isAccessorySelectionForEntireOrder) {
          const isSelected = itemSelected.value[`is_checkbox_of_${length}_selected`]
          hasErrors = isSelected && value === 0
        }

        if (hasErrors) break
      }

      if (hasErrors) {
        errorMessage.value = `values must be between ${minLength} and  ${maxLength}`
      }

      if (itemSelected.value.measure_type === 'LNTH' && !hasErrors && values.filter(Number).length === 0) {
        errorMessage.value = "At least one length is required"
      }

      if (itemSelected.value.measure_type === 'SQR' && !hasErrors && values.filter(Number).length < 2) {
        errorMessage.value = "Width and Drop are required."
      }

      if (props.isAccessorySelectionForEntireOrder) {
        //Check at least one checkbox is selected
        let atLeastOneCheckboxChecked = false
        for (const length of lengths.value) {
          const isSelected = itemSelected.value[`is_checkbox_of_${length}_selected`]

          if (isSelected) {
            atLeastOneCheckboxChecked = true
            break
          }
        }
        if (!atLeastOneCheckboxChecked && itemSelected.value.measure_type === 'LNTH') {
          errorMessage.value = "At least one length is required"
        }
      }

      return !errorMessage.value

    }

    const validate = () => {
      validateItem()
      return errorMessage.value ? false: true
    }

    const submit = async () => {
        validateItem()

        if (errorMessage.value) {
          return false
        }
        if (props.isAccessorySelectionForEntireOrder) {
          props.allData.selected_accessory = itemSelected.value
        } else {
          props.selection[props.currentSection].options['ACC'].option_settings = addedAccessories.value.length > 0 ? addedAccessories.value : null
        }
        return true

    }

    const edit = async (index) => {
      showAddNewAccessories.value = true
      emit('hideButtons', true)
      editingIndex.value = index
      itemSelected.value = addedAccessories.value[index]

      if (lengthTypes.value.length === 0) await getLengthTypes()
      let i = 0

      for (const length of ['length1_type', 'length2_type', 'length3_type', 'length4_type']) {

        if (itemSelected.value[length]) {
          const type = lengthTypesWithKeys.value.find(x => x.code === itemSelected.value[length])
          await selected(i, type.name, true)
        }
        i++
      }

      // await setDefaultValues()
    }

    if (props.isAccessorySelectionForEntireOrder) {
      emit('hideButtons', true)
      if (props.allData.selected_accessory) {
        itemSelected.value = { ...props.allData.selected_accessory }
        lengthInputs.value.forEach((input, index) => {
          if (itemSelected.value[`${input.model}`] > 0) {
            lengthInputs.value[index].input.disabled = false
          }
        })
        getLimitsAndColours()
      }

    }

    const selectedItemName = computed(() => {

      let fullName = ''
      if (selectedItemFromLeftSide.value) {
        fullName = selectedItemFromLeftSide.value.name
      } else if (itemSelected.value?.name) {
        fullName = styleList.value.find(x => x.id === itemSelected.value.style_id)?.name
      }

      if (itemSelected.value?.name && fullName) {
        fullName += `: ${itemSelected.value.name}`
      }

      return fullName
    })

    const updatedPrice = () => {
      optionLogic.setPriceForASingleChoice(props.currentSection, props.items.optionCode, { option_settings: addedAccessories.value })
    }

    const deleteAccessory = async (index) => {
      addedAccessories.value.splice(index, 1)
      updatedPrice()
    }

    function addAccessory() {
      showAddNewAccessories.value = true
      rightSideList.value = []
      search.value = ''
      getTypeAndStyles()
      selectedItemFromLeftSide.value = null
      editingIndex.value = null
      itemSelected.value = {}
    }

    function closeEditor() {
      showAddNewAccessories.value = false
      rightSideList.value = []
      selectedItemFromLeftSide.value = null
      editingIndex.value = null
      itemSelected.value = {}
    }


    return {
      validate,
      firstDimension,
      formatter,
      initialData,
      styleList,
      hardwares,
      lengthOrSheets,
      selectedItemFromLeftSide,
      search,
      getTypeAndStyles,
      rightSideList,
      leftSideListItemClicked,
      getListItems,
      getData,
      searchItems,
      toggleLengthList,
      toggleHardwareList,
      key,
      showAddNewAccessories,
      itemSelected,
      input,
      showSelectedItemForm,
      showLengthInputs,
      lengthInputs,
      selected,
      selectColor,
      getLengthTypes,
      back,
      addedAccessories,
      addOrUpdate,
      submit,
      addAccessory,
      edit,
      closeEditor,
      editingIndex,
      validateItem,
      limits,
      setDefaultValues,
      checkBoxClicked,
      errorMessage,
      selectedItemName,
      rightSideSelectedBySystem,
      selectLeftSideAutomatically,
      updatedPrice,
      deleteAccessory,
      itemList,
      frame_colour_id,
      addOnce,
      loading,
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .select_dropdown {
  z-index: auto;
}

.qty_lable_column {
  width: 80px;
}

.length_input_lable {
  width: 80px;
}

.small {
  font-size: 10px;
}

.input_flex {
  display: flex;
}

.row {
  display: table;
  border-spacing: 12px;
}

.column {
  display: table-cell;
}

.accessories {
  width: 100%;
  height: calc(100% - 15px);
  overflow: visible;
  // overflow-y: hidden;
}

.accessories_select {
  width: 100%;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  height: calc(100% - 30px);

  .editor-title {
    width: 100%;
  }
}

::v-deep .center svg.svg-inline {
  border-radius: 50%;
  padding: 12px;
  border: 2px solid #51b592;
  margin-top: 25px;
  cursor: pointer;

}

.item_main_div {
  max-height: 50vh;
  overflow-y: scroll;
  padding-bottom: 15px;
}

.no_data {
  height: 40px;
  width: 100%;
  padding: 10px 20px;
  text-align: center;
  font-style: italic;
  font-size: 17px;
  line-height: 140%;
  outline: none;
  resize: none;
}

li {
  list-style: none;
  padding: 0px 1.5rem;
  color: rgba(0, 0, 0, 1);
  font-family: Sansation;
  font-weight: Bold;
  font-size: 18px;
  opacity: 1;
  text-align: left;
}

.accessory_list {
  height: 40vh;
  overflow-y: scroll;
  padding-bottom: 15px;

  & li {
    margin-top: 5px;
    margin-right: 8px;
    background: rgba(224, 247, 238, 1);
    opacity: 1;
    padding: 6px 0 6px 10px;
    border-radius: 4px;
    overflow: hidden;
    cursor: pointer;
  }
}

.input {
  border: 1px solid rgba(11, 53, 83, .2);
  border-radius: 2px;
  height: 40px;
  width: 100%;
  padding: 0 13px 0 15px;
  font-weight: 700;
  font-size: 17px;
  line-height: 140%;
  outline: none;
  resize: none;
}

.sub_title {
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  margin-left: 8px;
  text-transform: uppercase;
  color: #000;
  text-align: left;

  & span {
    padding-top: 1px;
    float: right;
    padding-right: 3px;
    cursor: pointer;
  }
}


.other_items {
  padding: 8px;
  background: rgba(149, 174, 192, 0.25);
  opacity: 1;
  margin: 10px 0 10px 10px;

  border-radius: 9px;
  overflow: hidden;

  & .sub_title {
    margin-bottom: 5px;
  }

}

.item_sub_title {
  font-size: 15px;
  line-height: 140%;
  color: #000;
  margin-top: 2px;
  font-weight: bold;
  text-align: left;
  cursor: pointer;
  padding: 6px;

  &:hover {
    background-color: #c6d1da;
    border-radius: 4px;

  }
}

.item_title {
  text-transform: uppercase;
  width: 100%;
  color: rgba(0, 0, 0, 1);
  font-family: Sansation;
  font-weight: Bold;
  font-size: 24px;
  opacity: 1;
  text-align: left;
}

.top_item {
  padding: 8px;
  width: 100%;
  background: rgba(89, 182, 146, 0.30000001192092896);
  opacity: 1;
  border-radius: 9px;
  overflow: hidden;
}

.column_sub_title {
  width: 241px;
  color: rgba(0, 0, 0, 1);
  position: absolute;
  font-family: Sansation;
  font-weight: Bold;
  font-size: 26px;
  opacity: 1;
  text-align: left;
}

.step__title {
  font-size: 24px;
  line-height: 27px;
  text-transform: uppercase;
  color: #000;
  font-weight: 700;
  text-align: left;
  font-family: Sansation;
}

.accessories {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;

  .editor-title {
    width: 100%;
  }

}


.left {
  -ms-flex: 0 0 30%;
  -webkit-box-flex: 0;
  flex: 0 0 30%;
  max-width: 100%;
}

.right {
  -ms-flex: 0 0 70%;
  -webkit-box-flex: 0;
  flex: 0 0 70%;
  max-width: 70%;
  padding: 10px 20px 16px 20px;

  & .sub_title {
    margin-bottom: 10px;
    margin-left: 0;
  }

  & input {
    margin-bottom: 10px;
  }

}

@media only screen and (max-width: 768px) {
  .left {
    -ms-flex: 0 0 100%;
    -webkit-box-flex: 0;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .right {
    -ms-flex: 0 0 100%;
    -webkit-box-flex: 0;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.selected_item {
  background-color: #3CD29850;
  border-radius: 4px;
  padding: 6px 6px 6px 10px;
  margin-right: 6px;

  :deep(svg) {
    color: #3F9775;
  }
}

.column {
  -webkit-box-orient: vertical !important;
  flex-direction: column !important;
  -webkit-box-direction: normal !important;
}

.collapsed {
  height: 38px;
  overflow: hidden;

  & div {
    overflow: hidden;
  }
}

.add_btn {
  padding: 4px 16px;
  text-transform: uppercase;
  display: flex;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 0.02em;
  white-space: nowrap;
  -webkit-box-align: center;
  line-height: 140%;
  background: var(--PrimaryColour);
  color: white;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  right: 0;
}

.left_items {
  display: flex;
}

.validation_text {
  position: unset;
  text-align: right;
  margin-bottom: 8px;
}

.wizard__btn_back {
  cursor: pointer;
}

::v-deep .select-colour {
  // margin-left: 20px !important;

  & div {
    margin: auto;
  }

  & img {
    margin-left: 10px !important;
  }
}

.main_row {
  width: 90%;
}

.row_spacing {
  border-spacing: 17px;
}
</style>
