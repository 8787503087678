<template>
    <PortSetsForm
      :items="items"
      :currentSection="currentSection"
      :currentSettings="currentSettings"
      :selection="selection"
      name="Hopper Hatch"
      @error="$emit('error', $event)"/>
</template>

<script>
import PortSetsForm from './PortSetsForm.vue'

export default({
    props: ['items', 'currentSection', 'currentSettings', 'selection'],
    components: {
        PortSetsForm
    },
})
</script>
