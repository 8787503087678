<template>
    <Modal class="action-required-modal" v-model="modelValue" @update:modelValue="$emit('update:modelValue', $event)">
        <div style="width: 100%;">
            <div class="modal__title">
                Action required
            </div>
            <div class="modal__desc">
                {{ question }}
                <br>
                <br>
                Please select one of the following options:
            </div>
            <div class="modal__actions">
                <Button :data="item" v-for="item in actions" :key="item.title" @click="$emit(`${item.event}`)"/>
            </div>
        </div>
    </Modal>
</template>

<script>
import {ref} from 'vue'
import Button from '../../../form/Button.vue'
import Modal from '../../../elements/Modal.vue'

export default ({
    props: ['modelValue', 'cylMode'],
    components: {
        Modal,
        Button
    },
  setup(props, {emit}) {
      const actions = ref([
            {
                type: 'primary',
                title: 'Yes, remove it',
                event: 'remove'
            },
            {
                type: 'primary',
                title: 'Let me choose',
                event: 'goToOption'
            },
            {
                type: 'primary',
                title: 'No, keep it',
                event: 'keepIt',
            },
        ])
      const question = ref ('')


      if (props.cylMode == 'remove')
        question.value = 'Selected type of lock does not include a\n                cylinder but cylinder is selected.\n                Do you want remove the cylinder from  this item?\n                Please select one of the following options'

      if (props.cylMode == 'add') {
        question.value = 'Selected type of lock includes a\n' +
          'cylinder but cylinder is not selected.\n' +
          'Do you want to add a cylinder?\n' +
          'Please select one of the following options'
        actions.value = [
          {
            type: 'primary',
            title: 'Let me choose',
            event: 'goToOption'
          },
          {
            type: 'primary',
            title: 'I don\'t need it',
            event: 'keepIt',
          },
        ]
      }

        return {
            actions,
          question
        }
    }
})
</script>

<style scoped lang="scss">
.modal {
    &__title {
        text-decoration: uppercase;
        font-size: 26px;
        text-align: left;
        font-family: Sansation;
        width: 100%;
        text-align: center;
    }
    &__desc {
        font-size: 16px;
        font-weight: 300px;
        margin-top: 20px;
        text-align: left;
    }
    &__actions {
        display: flex;
        align-items: stretch;
        justify-content: space-around;
        flex-wrap: wrap;
        margin-top: 20px;
    }
}
.action-required-modal {
    :deep(.modal) {
      width: 640px;
      height: auto;
      max-width: 100%;
      padding: 15px !important;
    }
}
</style>
