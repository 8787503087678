<template>
    <div class="option-step" v-if="sizeOptions && flapOptions && positionOptions">
        <div class="option-step__left">
            <div class="option-step__options" style="display: flex;">
                <div>
                    <div class="option-step__title">Size
                      <HelpTopic class="select__help-topic" :iframeData="helpTopicUrlSize" v-if="helpTopicUrlSize" />
                    </div>
                    <RadioGroupItems
                        class="option-step__item"
                        :vertical="true"
                        :items="currentSettings.groups.size.choices.map(i => { return {label: i.name, key: i.name, value: i}})"
                        :key="'id'"
                        v-model="sizeValue"
                        @update:modelValue="updValues(sizeValue, 'size')"/>
                </div>
                <div class="option-step__" v-if="sizeValue && sizeValue.colour_group">
                    <span class="select-color__title">Select colour:</span>
                    <SelectColor :colour="sizeValue.colour" :colourGroup="sizeValue.colour_group" @submit="selectColor"/>
                </div>
            </div>
            <div class="option-step__options">
                <div class="option-step__title">Flaps
                  <HelpTopic class="select__help-topic" :iframeData="helpTopicUrlFlap" v-if="helpTopicUrlFlap" />
                </div>
                <RadioGroupItems
                    class="option-step__item"
                    :items="currentSettings.groups.flap.choices.map(i => { return {label: i.name, key: i.name, value: i}})"
                    :vertical="true"
                    :key="'id'"
                    v-model="flapValue"
                    @update:modelValue="updValues(flapValue, 'flap')"/>
            </div>
        </div>
        <div class="option-step__right">
            <div class="option-step__options" style="margin-bottom: 0;">
                <img  class="pet-door__image" :src="doorPositionImage" alt="door position"/>
                <div class="pet-door__title">Position
                  <HelpTopic class="select__help-topic" :iframeData="helpTopicUrlPosition" v-if="helpTopicUrlPosition" />
                </div>
                <RadioGroupItems
                    class="option-step__item"
                    :items="currentSettings.groups.position.choices.map(i => { return {label: i.name, key: i.name, value: i}})"
                    :vertical="true"
                    :key="'id'"
                    v-model="positionValue"
                    @update:modelValue="updValues(positionValue, 'position')"/>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, computed } from 'vue'
import RadioGroupItems from '../../../components/form/RadioGroupItems.vue'
import { useOptionLogic } from "../../../composables/estimate/create"
import SelectColor from '../../elements/SelectColor.vue'
import HelpTopic from "../../elements/HelpTopic";

export default({
    props: ['items', 'currentSection', 'data', 'currentSettings', 'selection'],
    components: {
        RadioGroupItems,
        SelectColor,
      HelpTopic
    },
    setup(props, { emit }) {
        const sizeOptions = ref(null)
        const sizeValue = ref({})

        const flapOptions = ref(null)
        const flapValue = ref({})

        const positionOptions = ref(null)
        const positionValue = ref({})
        const helpTopicUrlSize = computed(() => {
         return  props.data?.steps.find(el => el.option_code == 'PET').option_settings?.groups.find(el => el.name == 'size').help_topic_url
        })
        const helpTopicUrlFlap = computed(() => {
          return  props.data?.steps.find(el => el.option_code == 'PET').option_settings?.groups.find(el => el.name == 'flap').help_topic_url
        })
        const helpTopicUrlPosition = computed(() => {
          return  props.data?.steps.find(el => el.option_code == 'PET').option_settings?.groups.find(el => el.name == 'position').help_topic_url
        })
        const petDoorColor = computed(() => {
          return  sizeOptions.value.find(el => el.name == Object.values(sizeValue.value)[0]).colour
        })
        const doorPositionImage = computed(() => {
            return positionValue.value.image

        })

        const optionLogic = useOptionLogic(props)


        getData()
        function getData() {
            optionLogic.getData1(sizeOptions, sizeValue, 'size')
            optionLogic.getData1(flapOptions, flapValue, 'flap')
            optionLogic.getData1(positionOptions, positionValue, 'position')
        }

        function selectColor(color) {
          optionLogic.selectColor(color.id, sizeValue.value.id, 'size')
          sizeValue.value.colour = color.id
          emit('updateOptionColour')
        }


        function updValues(values, groupName) {
            optionLogic.selectItem(values, groupName)
        }

        return {
            helpTopicUrlSize,
            helpTopicUrlFlap,
            helpTopicUrlPosition,
            petDoorColor,
            optionLogic,
            positionOptions,
            flapOptions,
            sizeOptions,
            sizeValue,
            flapValue,
            positionValue,
            doorPositionImage,
            updValues,
            getData,
            // doorSizeValue,
            selectColor,
            // sizeColorValue
        }

    },
})
</script>


<style scoped lang="scss">
.option-step {
    display: flex;
    flex-direction: row;
    width: 100%;
}
.pet-door {
    &__image {
        max-height: 195px;
        display: flex;
        margin-right: auto;
        margin-bottom: 10px;
    }
    &__title {
        font-size: 24px;
        margin-bottom: 10px;
        text-align: left;
    }
}
.select-color {
    &__title {
        margin-bottom: 10px;
        display: block;
    }
}
</style>
