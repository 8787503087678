<template>
  <div class="items">

    <RadioGroupItems
      class="enter-size__panel"
      :items="typeOptions"
      :key="id"
      v-model="typeValue"
      @update:modelValue="updValues(typeValue, 'default'),validate(typeValue)"
    />

    <div v-show="typeValue" class="selected_item">
      <div class="checkboxes">
        <div class="item" v-for="(item, index) in visibleChoices" :key="`${item.index}_${currentSection}`">
          <span class="item__num">{{ index + 1 }}.</span>
          <Checkbox class="item__checkbox"
                    :data="{ key: `checkbox-${typeValue}-${index}` }"
                    :disabled="item.required"
                    v-model="item.selected"
                    @update:modelValue="updValues(typeValue,index), updateChoices(item, index)"/>
          <InputSize
            class="item__input"
            :data="{ pref: '', suf: 'mm', type: 'number', max: currentSection.drop }"
            :disabled="!item.selected"
            :item="item"
            v-model="item.position"
            @update:modelValue="validate(typeValue), updValues(typeValue,index)"
          />
        </div>
      </div>

      <template v-if="typeValue == 'MIDR' || typeValue == 'MULL'">
        <svg-icon-qouting v-if="typeValue == 'MIDR'"  class="item__icon" color="var(--SecondaryColour)" :data="currentSettings.groups[typeValue].icon"/>
        <svg-icon-qouting v-else-if="typeValue == 'MULL'"  class="item__icon" color="var(--SecondaryColour)" :data="currentSettings.groups[typeValue].icon"/>
      </template>

    </div>

    <div class="option-step__" v-if="getSelectedItemColorAndGroup.colour_group && getSelectedItemColorAndGroup.colour">
      <span class="select-color__title">Select colour:</span>
      <SelectColor :colour="getSelectedItemColorAndGroup.colour"
                   :colourGroup="getSelectedItemColorAndGroup.colour_group"
                   :frameColour="frameColour"
                   :sameAsFrame="getSelectedItemColorAndGroup.same_as_frame_colour"
                   @submit="selectColor"
      />
    </div>

  </div>
</template>

<script>
import InputSize from '../../form/InputSize.vue'
import Checkbox from '../../form/Checkbox.vue'
import RadioGroupItems from "../../form/RadioGroupItems";
import {useOptionLogic} from "../../../composables/estimate/create"
import {computed, reactive, ref, watch, onMounted} from 'vue'
import SelectColor from '../../elements/SelectColor.vue'

export default ({
  props: ['items', 'currentSection', 'currentSettings', 'selection', 'frameColour'],
  components: {
    InputSize,
    Checkbox,
    RadioGroupItems,
    SelectColor
  },
  setup: function (props, {emit}) {

    const optionLogic = useOptionLogic(props)

    const typeOptions = ref(null)
    const typeValue = ref({})

    const choices = ref([])
    const visibleChoices = ref([])

    // const visibleChoices = computed(() => props.currentSettings?.groups[typeValue.value]?.choices)

    function updateChoices(item, index) {

      if(item && !item.selected) {
        visibleChoices.value.forEach((item, i) => {
          if(i > index) {
            item.position = null
            if(!item.required) item.selected = false
          }
        })
      }

      const result = choices.value.filter(x => x.selected)
      const unselected = choices.value.find(x => !x.selected)

      if(unselected) {
        result.push(unselected)
      }

      visibleChoices.value = result
    }

    onMounted(() => {

      const items = (props.currentSettings?.groups[typeValue.value]?.choices ?? [])

      items.forEach((element, index) => {
        element['index'] = index
      });

      choices.value = items.sort((a, b) => {
        // Sort by required first (descending order)
        if (a.required !== b.required) {
          return b.required - a.required;
        }
        // Then sort by selected (descending order)
        return b.selected - a.selected;
      });

      updateChoices()

    })

    const valueKey = computed(() => props.currentSettings.currentKeyForValues.value)

    const optionCode = ref(props.items.optionCode)

    //MIDMUL
    const option = computed(() => props.items.sections[props.currentSection].options[optionCode.value])

    //This has both MIDR and MULL settings
    const settings = computed(() => option.value.option_settings)

    getData()

    function getData() {
        const items = (props.currentSettings?.groups[typeValue.value]?.choices ?? [])

        items.forEach((element, index) => {
            element['index'] = index
        });

        choices.value = items.sort((a, b) => {
            // Sort by required first (descending order)
            if (a.required !== b.required) {
                return b.required - a.required;
            }
            // Then sort by selected (descending order)
            return b.selected - a.selected;
        });

        updateChoices()


      typeValue.value = null

      let hasDisabled = false
      props.currentSettings.groups.default.choices.forEach((t) => {
        t.disabled = props.currentSettings.groups[t.id].settings.max == 0
        if (t.disabled) {
          if (t.default)
            delete t.default
          hasDisabled = true
        }else{
          props.currentSettings.groups[t.id].choices.forEach(i => {
            if (i.required)
              i.selected = true
          })
        }
      })

      optionLogic.getData1(typeOptions, typeValue, 'default')


      if (typeValue.value) {
        typeValue.value = typeValue.value.id
        updValues(typeValue.value, 'default')
      }
      else{
        if (hasDisabled)
          typeValue.value = typeOptions.value.find((i) => !i.disabled).id
          updValues(typeValue.value, 'default')
      }
    }

    function updValues(type, index) {
      if (index == 'default')
        optionLogic.selectItem(type, index)
      else{

        if (!props.currentSettings.groups[typeValue.value].choices[index].selected){

          props.currentSettings.groups[typeValue.value].choices[index].position = null
        }

        optionLogic.getPrice()
      }
    }

    //first validation
    function validate(type,item) {
      let valid = true

      props.selection.forEach((s, i) => {
        const option = s.options[props.items.optionCode]
        s.hasErrors = false

        const settingsData = option.option_settings
        const type = settingsData.groups.default.choices.find(i => i.default)
        const minMIDR = settingsData.groups['MIDR'].settings.min
        const minMULL = settingsData.groups['MULL'].settings.min

        if (!type || (type.id !== 'MIDR' && type.id !== 'MULL')){
          if (minMIDR > 0 && minMULL > 0){
            s.hasErrors = true
            emit('error', 'Midrail(s) or Mullion(s) is required')
          }else if (minMIDR > 0){
            s.hasErrors = true
            emit('error', `At least ${minMIDR} Midrail` + (minMIDR == 1 ? ' is' : 's are') + 'required')
          }else if (minMULL > 0){
            s.hasErrors = true
            emit('error', `At least ${minMULL} Mullion` + (minMULL == 1 ? ' is' : 's are') + 'required')
          }else {
            emit('error', '')
          }
        }

        if (!s.hasErrors) {
          const isMIDR = type.id === "MIDR"
          const {gap_max, gap_min} = settingsData.groups[type.id].settings

          settingsData.groups[type.id].choices.forEach(j => {
            if (j.required && (!j.position || !j.selected)) {
              j.error = 'Position is required'
              s.hasErrors = true
              valid = false
            } else
              j.error = ''
          })
          if (!s.hasErrors) {

            const choices = settingsData.groups[type.id].choices.filter(i => {
              return i.selected
            }).sort((a, b) => {
              return parseInt(a.position) - parseInt(b.position)
            })

            //if midr take drop else width
            const highestPoint = isMIDR ? parseInt(s.drop) : parseInt(s.width)
            const startPoint = 0

            choices.forEach((m, i) => {
              let gap_before = 0
              let gap_after = 0
              if (i == 0)
                gap_before = parseInt(m.position)
              else
                gap_before = parseInt(m.position) - parseInt(choices[i - 1].position)

              if (i == choices.length - 1)
                gap_after = parseInt(highestPoint) - parseInt(m.position)
              else
                gap_after = parseInt(choices[i + 1].position) - parseInt(m.position)

              if (gap_before > gap_max || gap_before < gap_min ||
                  gap_after > gap_max || gap_after < gap_min) {
                const emitData = `Error: min distance between ${type === "MIDR" ? 'midrails' : 'mullions'} and/or frame is ${gap_min} and max is ${gap_max} mm`
                s.hasErrors = true
                emit('error', emitData)
              } else
                emit('error', '')

            })
          }
        }
        valid = valid && !s.hasErrors
      })

      return valid

    }

    const getSelectedItemColorAndGroup  = computed(() => {
      const selected = props.currentSettings.groups[typeValue.value]

      const colour = selected?.choices[0]?.colour
      const colour_group =  selected?.settings.colour_group
      const same_as_frame_colour =  selected?.choices[0]?.same_as_frame_colour
      return {colour:colour, colour_group:colour_group, same_as_frame_colour:same_as_frame_colour}
    })

    function selectColor(colour) {
      const section = props.selection[props.currentSection]
      const settings = section.options['MIDMUL'].option_settings.groups[typeValue.value]

      settings.choices.forEach((value) => {
        value.colour = colour.id
        value.same_as_frame_colour = colour.is_same_as_frame ?? false
      })
      emit('updateOptionColour')
    }

    function submit(){

      props.currentSettings.groups.default.choices.forEach((t) => {
        props.currentSettings.groups[t.id].choices.forEach((i) => {
          if (!t.default)
            i.selected = false

          if (!i.selected)
            i.position = null
        })
      })


      return validate()
    }

    return {
      updateChoices,
      choices,
      validate,
      updValues,
      getSelectedItemColorAndGroup,
      selectColor,
      submit,
      visibleChoices,
      typeOptions,
      typeValue,
      getData
    }
  },
})
</script>

<style scoped lang="scss">
.checkboxes{
  margin-top:20px;
}
.selected_item {
  display: flex;
  gap: 20px;
}

.item {
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  &__checkbox {
    margin-bottom: 0;
    width: auto;
  }

  &__num {
    display: block;
    margin-right: 10px;
    font-size: 20px;
  }

  &__icon {
    font-size: 120px;

    &-wrapper {
      height: 100%;
      display: flex;
      align-items: center;
      align-self: center;
    }

    @media(max-width: 768px) {
      font-size: 120px;
    }
  }
}
</style>
