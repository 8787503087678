<template>
    <div class="option">
        <div class="sections"
            v-if="currentStepItems.sections?.length > 1
            && currentStepItems.appliesTo == 'section'">
                <svg-icon-qouting

                    class="sections__item"
                    :class="{ 'active': currentSectionIndex == index, 'invalid': selection[index].hasErrors }"
                    :data="item.icon"
                v-for="(item, index) in currentStepItems.sections"
                :key="index"
                    @click="updCurrentSection(index)"
                    />
            </div>

        <component
            ref="refComponent"
            :area="area"
            :is="component.name"
            :class="component.className"
            :currentSection="currentSectionIndex"
            :currentSettings="currentSettings"
            :isAccessorySelectionForEntireOrder="showAddNewAccessories"
            :selection="selection"
            :error="error"
            :allData="allData"
            :data="itemsCloned"
            :items="currentStepItems"
            :frameColour="frameColour"
            @error="error = $event"
            @updColor="$emit('updColor')"
            @updColorSection="$emit('updColorSection')"
            @needShowModal="needShowModal = $event"
            @nextOption="nextOption = $event, submit('submit')"
            v-if="component.name && currentStepItems"
            @updateOptionColour="updateOptionColour"
            @hideButtons="(val) => $emit('hideButtons',val)"
            @setOption="updateSelectionOption"
        />
        <span
            class="validation_text"
            v-show="error">
            *{{ error }}
        </span>
    </div>
</template>

<script>
import OptionOneRadioGroup from './options/OptionOneRadioGroup.vue'
import Insert from './options/Insert.vue'
import PetDoors from './options/PetDoors.vue'
import Lock from './options/Lock.vue'
import Hinges from './options/Hinges.vue'
import MidrailsAndMullions from './options/MidrailsAndMullions.vue'
import MidrailsAndMullionsMerged from "./options/MidrailsAndMullionsMerged.vue";
import Additions from './options/Additions.vue'
import HooperHatches from './options/HooperHatches'
import LiftTabs from './options/LiftTabs'
import PushPins from './options/PushPins'
import MeshFlaps from './options/MeshFlaps'
import PortSets from './options/PortSets'
import Cylinder from './options/Cylinder'
import OutOfSquare from './options/OutOfSquare'
import Accessories from './options/Accessories'
import _ from 'lodash'


import {computed, ref, watch} from 'vue'
export default({
    props: ['items', 'allData','showAddNewAccessories', 'area'],
    components: {
        OptionOneRadioGroup,
        PetDoors,
        Insert,
        Lock,
        Hinges,
        MidrailsAndMullions,
        MidrailsAndMullionsMerged,
        Additions,
        HooperHatches,
        LiftTabs,
        PushPins,
        MeshFlaps,
        PortSets,
        Cylinder,
        OutOfSquare,
        Accessories
    },
    setup(props, {emit}) {
        const refComponent = ref(null)
        const error = ref(false)

        const selection = ref(null)

        const nextOption = ref(null)

        const needShowModal = ref(false)

        const itemsCloned = ref(_.cloneDeep(props.items))

        const appliesTo = computed(() => {
            const optionCode = itemsCloned.value.current_step.option_code
            return itemsCloned.value.steps.find(el => el.option_code == optionCode)?.applies_to
        })

        watch(appliesTo, (val, oldVal) => {
            if(val === 'layout') {
                const stepSize = itemsCloned.value.steps.find(el => el.name.toLowerCase() == 'size')
                const sections = stepSize?.data.sections
                resetIndexToFirstPanel(sections.map(i => String(i.id)).indexOf('1'))
            }
        }, {immediate: false})

        const currentStepItems = computed(() => {
            const optionName = itemsCloned.value.current_step.option
            const optionCode = itemsCloned.value.current_step.option_code
            const currentOption = itemsCloned.value.steps.find(el => el.option_code == optionCode)

            const stepSize = itemsCloned.value.steps.find(el => el.name.toLowerCase() == 'size')
            const appliesTo = itemsCloned.value.steps.find(el => el.option_code == optionCode)?.applies_to
            const sections = stepSize?.data.sections

          return  {
            sections,
            appliesTo,
            optionName,
            optionCode
          }
        })

        const resetIndexToFirstPanel = (i) => {
          currentSectionIndex.value = i
        }

      const frameColour = computed(() => {
        const colourStep = itemsCloned.value.steps.find(el => el.name.toLowerCase() == 'frame colour')
        if (colourStep)
          return colourStep.data.id

        return null
      })


        const component = computed(() => {
            const optionCode = currentStepItems.value.optionCode
            const optionName = currentStepItems.value.optionName

            let value = ''
            switch(optionCode) {
                case 'ACC':
                value = 'accessories'
                break
                case 'PET':
                    value = 'pet-doors'
                    break
                case 'PSHP':
                    value = 'push-pins'
                    break
                case 'LOCK':
                    value = 'lock'
                    break
                case 'INS':
                    value = 'insert'
                    break
                case 'ADD':
                    value = 'additions'
                    break
                case 'HNG':
                    value = 'hinges'
                    break
                case 'LFTT':
                    value = 'lift-tabs'
                    break
                case 'HOPH':
                    value = 'hooper-hatches'
                    break
                case 'MFLP':
                    value = 'mesh-flaps'
                    break
                case 'PRTS':
                    value = 'port-sets'
                    break
                case 'CYL':
                    value = 'cylinder'
                    break
                case 'MIDR':
                case 'MULL':
                    value = 'midrails-and-mullions'
                    break
                case 'WHL':
                case 'MESH':
                    value = 'option-one-radio-group'
                    break
                case 'OSQR':
                  value = 'out-of-square'
                  break
                case 'MIDMUL':
                  value = 'midrails-and-mullions-merged'
                  break
                  default: value = ''
            }

            return {
                className: optionName && optionName.split(' ').join('-').toLowerCase(),
                name: value
            }
        })

        const wizardParams = sessionStorage.getItem('wizardParams') && JSON.parse(sessionStorage.getItem('wizardParams')) || {}
        const defaultSectionIndex = currentStepItems.value.sections?.length ? currentStepItems.value.sections.findIndex(i => i.id == 1 ): 0

        const currentSectionIndex = ref(wizardParams.index || defaultSectionIndex)

        initSelection()


        function initSelection(){
          selection.value = getSections();
        }

        const currentSettings = computed(() => {
          const currentSection = selection.value[currentSectionIndex.value]
          const currentOption = currentSection?.options[itemsCloned.value.current_step.option_code] || {}
          return currentOption.option_settings || {}
        })

      function updateSelectionOption(section, option, value){
        selection.value[section].options[option] = value
      }

        async function submit(type) {
            const componentsWithAsyncSubmitFunction = ['out-of-square']
            const isAsync = componentsWithAsyncSubmitFunction.includes(component.value.name)
            if(error.value) return
            if(needShowModal.value) {
                refComponent.value.showModal(true)
                needShowModal.value = false
                return
            }

            if (refComponent.value?.validate && !refComponent.value.validate()) return false

            if(refComponent.value.submit) {
                let result = isAsync? (await refComponent.value.submit(type)): refComponent.value.submit(type)
                if(!result) return
            }

            // if (refComponent.value.submit && !refComponent.value.submit(type)) return

            if (currentStepItems.value.sections?.length > 1
              && currentStepItems.value.appliesTo == 'section'
              && selection.value.filter(s => {return s.hasErrors}).length > 0) return

            // const sections = getSections()
            const sections = selection.value
            const nextStep = nextOption.value || 'next'
            emit('optionSubmit', { sections , type, nextStep })
            // emit('optionSubmit', { sections , type, nextStep })
            nextOption.value = ''

          return true
        }

        function getSections() {
            const optionCode = itemsCloned.value.current_step.option_code
            const sizeStep = itemsCloned.value.steps.find(el => el.name.toLowerCase() == 'size')
            const sections = sizeStep ? JSON.parse(JSON.stringify(sizeStep.data.sections)) : []
            sections.forEach((el) => {
                const currentOption = el.options[optionCode]
                el.options = { [optionCode]: currentOption }
            })
            return sections
        }

        async function updCurrentSection(index) {
            const wizardParams = sessionStorage.getItem('wizardParams') && JSON.parse(sessionStorage.getItem('wizardParams')) || {}
            wizardParams['index'] = index
            sessionStorage.setItem('wizardParams', JSON.stringify(wizardParams))

     //       if (refComponent.value && refComponent.value.validate && !refComponent.value.validate(true)) return

            if(refComponent.value && refComponent.value.processBeforeExit){
                await refComponent.value.processBeforeExit()
                currentSectionIndex.value = index
            }else {
                currentSectionIndex.value = index
            }

            setTimeout(() => {
                refComponent.value.getData()
            })
        }

        function updateOptionColour(){
          emit('submit', { selection: selection.value, option: itemsCloned.value.current_step.option_code } , 'setOptionColor')
        }

        //To fix osqr rendering issue
        if (currentStepItems.value.optionCode === 'OSQR' && currentStepItems.value.sections.length > 1) {
            for (const section of currentStepItems.value.sections) {
                const index = currentStepItems.value.sections.indexOf(section);
                updCurrentSection(index)
            }

            updCurrentSection(0)
        }

        return {
            currentStepItems,
            component,
            refComponent,
            currentSectionIndex,
            submit,
            updCurrentSection,
            error,
            nextOption,
            needShowModal,
          currentSettings,
          selection,
          updateOptionColour,
          frameColour,
          itemsCloned,
          updateSelectionOption
        }
    },
})
</script>


<style scoped lang="scss">
.option {
    position: relative;
//   height: 100%;
}
.sections {
    display: flex;
    margin-bottom: 20px;
    &__item {
        display: flex;
        font-size: 60px;
        cursor: pointer;
        ::v-deep .svg-inline {
            width: 20px;
            &.width_2 {
                width: 40px;
            }
            &.width_0_5 {
                height: 20px;
                width: auto;
            }
        }
        width: 20px;
        ::v-deep .svg-icon-secondary-colour, ::v-deep  .svg-icon-primary-colour{
            fill: #777777;
        }

        &.active {
            ::v-deep .svg-icon-secondary-colour {
                path {
                    fill: var(--LayoutIconSecondary);
                }
            }
            ::v-deep .svg-icon-primary-colour {
                path {
                    fill: var(--LayoutIconPrimary);
                }
            }
        }
    }
}
</style>

<style lang="scss">
.option-step {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    width: 100%;
    &__right, &__left {
        width: 50%;
        @media(max-width: 768px) {
            width: 100%;
        }
        .step__options {
            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }
    &__color {
        text-align: left;
        margin: 10px 0;
        span {
            display: inline-block;
            margin-bottom: 5px;
            font-size: 14px;
            line-height: 100%;
        }
    }
    &__note {
        border-top: 1px solid #000;
        padding-top: 10px;
        margin-top: 30px;
    }
    &__options {
        margin-bottom: 30px;
        width: 33%;
        flex-wrap: wrap;
        @media(max-width: 768px) {
            margin-bottom: 10px;
            width: 100%;
        }
      @media(max-width: 1024px) {
        width: 100%;
      }
    }
    &__item {
        min-width: 225px;
        margin-bottom: 10px;
        display: flex;
        flex-wrap: wrap;
        ::v-deep .radio-group__item {
            margin-bottom: 10px;
        }
    }
    &__image {
        max-height: 195px;
        display: flex;
        margin-right: auto;
        margin-bottom: 10px;
    }
    &__price {
        position: absolute;
        right: 25px;
        bottom: 28px;
        color: #fff;
        transform: translateY(50%);
    }
    &__title {
        font-size: 24px;
        margin-bottom: 10px;
        text-align: left;
    }
}
.sections__item {
  width: 60px !important;
    padding-bottom: 3px;

    &.active {
        border-bottom: 2px solid #1C2833;
        padding-bottom: 2px;


      &.invalid {
        border-bottom: 2px solid #1C2833;
      }

    }
    & .svg-inline {
      width: 60px !important;
    }

  &.invalid {
    background-color: #C0392B30;
  }
}

</style>
