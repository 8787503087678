<template>

  <div class="add_accessory">
    <p v-if="items.length === 0">You have not created any accessory yet. Please click the button to
      create an accessory.</p>

    <div v-else>
      <table class="tw-table-auto tw-border-collapse tw-w-full tw-text-sm md:tw-text-base tw-text-left">
        <thead class="tw-font-bold">
          <tr>
            <th class="tw-p-2 md:tw-p-4">Accessory</th>
            <th class="tw-p-2 md:tw-p-4 tw-text-right">Size</th>
            <th class="tw-p-2 md:tw-p-4 tw-text-right">Quantity</th>
            <td v-if="showPrice" class="tw-p-2 md:tw-p-4 tw-text-right">Price</td>
            <td class="tw-p-2 md:tw-p-4"></td>
          </tr>
          
        </thead>
        <tbody class="tw-font-normal">
          <tr v-for="(item,index) in items" :key="index">
            <td class="tw-p-2 md:tw-p-4 tw-py-1 md:tw-py-1">
              {{ item.name }}
              <p v-if="item.colour" class="text_small tw-text-xs text_weight_light">{{item.colour.full_name}}
                <template v-if="item.is_same_as_frame"> (same as frame)</template>
              </p>
            </td>
            <td class="tw-p-2 md:tw-p-4 tw-py-1 md:tw-py-1 tw-text-right">
              <ul v-if="item.measure_type === 'SQR'"><li>{{item.length1}}*{{item.length2}}</li></ul>
              <ul v-else-if="item.measure_type === 'LNTH'">
                <li v-if="item.length1">{{item.length1}} </li>
                <li v-if="item.length2">{{item.length2}}</li>
                <li v-if="item.length3">{{item.length3}} </li>
                <li v-if="item.length4">{{item.length4}} </li>
              </ul>
            </td>
            <td class="tw-p-2 md:tw-p-4 tw-py-1 md:tw-py-1 tw-text-right">{{ item.qty }}</td>
            <td v-if="showPrice" class="tw-p-2 md:tw-p-4 tw-py-1 md:tw-py-1 tw-text-right">{{ formatter.formatMoney(item.price) }}</td>
            <td class="tw-p-2 md:tw-p-4 tw-py-1 md:tw-py-1">
              <div class="tw-flex tw-gap-1.5 tw-w-full tw-justify-end tw-cursor-pointer">
                <svg-icon-qouting src="/images/svg/pen-to-square.svg"
                              @click="$emit('edit',index)"/>
            <svg-icon-qouting src="/images/svg/trash.svg"
                              @click="showDeleteConfirmation = true,indexOfTheAccessoryThatNeedToBeDeleted=index"/>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

    </div>
    <div class="add-button" @click="$emit('hideButtons'),$emit('add')">
      <svg-icon name="plus-circle"/>
      <br/>Add new accessory
    </div>
    <Teleport to="body">
      <DeleteConfirmationModal v-model="showDeleteConfirmation" @deleteEstimate="$emit('delete',indexOfTheAccessoryThatNeedToBeDeleted),showDeleteConfirmation =false"/>
    </Teleport>
  </div>
</template>

<script setup>
import {useFormatter} from '@/components/item-wizard/composables/formatter/index.js'
import DeleteConfirmationModal from '@/components/item-wizard/components/modals/DeleteConfirmationModal'
import {ref, defineProps, computed} from "vue";
import { useStore } from 'vuex';

const props = defineProps({items: {type: Array, required: true}, area: {type: String}})
const formatter = useFormatter()
const showDeleteConfirmation = ref(false)
const indexOfTheAccessoryThatNeedToBeDeleted = ref(0)
const store = useStore()

const showPrice = computed(() => store.getters['auth/showPrice'](props.area))

</script>

<style lang="scss" scoped>

::v-deep .custom {
  width: 15px!important;
  @media (min-width: 768px) {
    width: 20px!important;
  }
}

.accessory_image{
  width: 100px;
  margin-right: 10px;
}
.add_accessory {
  width: 98%;
  text-align: center;

  .add-button {
    cursor: pointer;
    color: var(--PrimaryButtonColour);
    :deep(svg) {
      font-size: 3rem;
    }
  }
}

.row {
  display: flex;
}

.col:nth-of-type(1) {
  flex-grow: inherit
}

.col {
  height: auto;
  /*  background-color: #e5e5e5;*/
  margin: auto;
  text-align: center;
}
.actions{
  display:flex;
  justify-content: space-between;

  &__btn{
    ::v-deep .icons__wrapper {
      margin: 0 8px;
      cursor: pointer;
    }
  }
}
.selected_accessory_name{
  text-align: left;
  flex-basis:40%;
}
.selected_accessory_image{
  flex: 0 0 128px;
}
.row_without_image{
  margin-bottom: 10px;
}
</style>
