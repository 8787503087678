<template>
    <div class="select-colour" :class="{'show-label-at-left':showLabelAtLeft}" @click="showModal">
      <div v-if="showLabelAtLeft">  {{colourName}}<p v-if="sameAsFrame" class="text_small"> (same as frame)</p></div>
      <Loader v-if="!src"/>
      <div v-else>
        <img :src="src" :alt="colourName">
      </div>

      <span v-if="!showLabelAtLeft">{{colourName}}<p v-if="sameAsFrame" class="text_small"> (same as frame)</p>
      </span>
    </div>
    <SelectColorModal
        class="select-colour__modal"
        v-if="show"
        v-model="show"
        :items="items"
        @submit="selectColour"
    />

  <PCAlert class="action-required-modal" v-if="showPCAlert"
         :modelValue="true"
         :show="showPCAlert"
         @update:show="showPCAlert=event"
         @confirmClicked="continueWithColour"
  >
  </PCAlert>
</template>

<script>
import PCAlert from '../modals/PCChargeAlertModal.vue'
import SelectColorModal from '../modals/SelectColorModal.vue'
import api from '../../services/wizard.service'
import Loader from '@/components/item-wizard/components/elements/Loader.vue'
import { ref, onMounted, watch } from 'vue'
// import store from "../../../../store/store";
import {pcChargeStore} from "../estimate/pc-charge/store";

export default ({
    props: ['colourGroup', 'colour', 'frameColour','showLabelAtLeft', 'sameAsFrame', 'containerId', 'itemId'],
    components: {
      SelectColorModal,
      Loader,
      PCAlert
    },
    setup(props, { emit }) {
        watch( () => props.colour, async (value, prevValue) => {
          let colourImage = await api.getColor(props.colour)
          src.value = colourImage.data?.image_url
          colourName.value = colourImage.data?.name
        })
        const show = ref(false)
        const items = ref(null)
        const src = ref ()
        const colourName = ref (null)
        const showPCAlert = ref (false)
      let selectedColour = null;

        function showModal(value, colour) {
            show.value = value
            if(value && !items.value) getData()
        }
        onMounted(async () => {
          let colourImage = await api.getColor(props.colour)
          src.value = colourImage.data?.image_url
          colourName.value = colourImage.data?.name
        })
        async function getData() {
            items.value = { data: await api.getColors(props.containerId, props.colourGroup, {frame_colour: props.frameColour, item_id: props.itemId}) }
        }

        function selectColour(e){

          if (e.supplier_stocked === false && pcChargeStore.supressAlert !== true){
            showPCAlert.value = true
            selectedColour = e
            return
          }

          emit('submit', e)
          showModal(false, e)
        }

        function closePcAlert(){
          showModal(false)
        }

        function continueWithColour(){
          emit('submit', selectedColour)
          showModal(false)
        }

        return {
          src,
            showModal,
            show,
            getData,
            items,
          colourName,
          selectColour,
          showPCAlert,
          closePcAlert,
          continueWithColour
        }
    },
})
</script>


<style scoped lang="scss">
.show-label-at-left{
  display:-webkit-box;
}
.select-colour {
    width: 100px;
    cursor: pointer;
    img {
        max-width: 100%;
        border: 2px solid #000;
    }
    ::v-deep .spinner svg{
      font-size: 32px;
    }
    &__modal {

        // height: 100%;
        ::v-deep .modal {
            padding: 20px;
            @media(max-width: 768px) {
                padding: 30px 20px 20px;
            }
        }
    }
}
.estimate {
    .select-colour__modal {
        height: 100%;
    }
}
.modal-wrapper.show.select-color-modal.select-colour__modal {
  min-width: auto !important;
}
</style>
