<template>
  <div class="option-step" v-if="typeOptions && heightOptions && sideOptions">
    <div class="option-step__options">
      <div class="option-step__title">Lock Type</div>
      <RadioGroupItems
        class="door__item"
        :items="currentSettings.groups.default.choices.filter(i => (!i.is_first_section_only
        || (i.is_first_section_only === true && items.sections[currentSection].id == 1)))
        .map(i => { return {label: i.name, key: i.id, value: i}})"
        :key="'id'"
        v-model="typeValue"
        @update:modelValue="updValues(typeValue, 'default'), checkHeightAvailable()"/>

      <Checkbox
        :data="{ key: 'triple_lock_adjust', label: 'Adjust Triple Lock Points for Flush Bolt matching' }"
        v-model="isAdjustTripleLockPrints"
        @update:modelValue="(val) => currentSettings.settings.adjust_triple_lock = val"
        v-if="typeValue && typeValue.is_adjust_triple_lock_prints"
      />

      <Checkbox
        :data="{ key: 'flip_door', label: 'Flip Door' }"
        v-model="isFlipDoor"
        @update:modelValue="flipDoor($event)"
        v-if="currentSettings.settings?.is_flip_door_available"
      />
      <SelectColour
        v-if="typeValue && typeValue.colour"
        :colourGroup="typeValue.colour_group"
        :colour="typeValue.colour"
        @submit="setColour"
      />
    </div>
    <div class="option-step__options" v-if="isHeightAvailable">
      <div class="option-step__title">Lock Height</div>
      <RadioGroupItems
        class="door__item"
        :items="currentSettings.groups.lock_height.choices.map(i => { return {label: i.name, key: i.name, value: i }})"
        :key="'id'"
        v-model="heightValue"
        @update:modelValue="updValues(heightValue, 'lock_height'), validateHeight()"/>
      <Checkbox
        :data="{ key: 'makeDefault', label: 'Make this my default selection for'}"
        v-model="saveDefaultHeight"
        v-if="!isQuoting && isHeightAvailable"
        :class="'w-100'"
      />
      <SelectDropdown
        v-if="saveDefaultHeight && isHeightAvailable"
        :list="saveDefaultHeightOptions"
        :listIsObject="true"
        :small="true"
        @select="(val) => selectedDefaultHeightFor = val"
        :selected="selectedDefaultHeightFor"
        type="id"
      ></SelectDropdown>

      <InputGroup
        v-if="isHeightAvailable"
        class="overall__input"
        :data="inputs.height"
        v-model="lockHeight"
        @update:modelValue="(val) => currentSettings.settings.height = val"
        @input="validateHeight"
      >
      </InputGroup>

      <div class="lock-height__image"
           v-if="heightImage"
           :key="heightImage.image">
        <div class="lock__image-wrapper">
          <img class="lock__image" :src="heightImage.image" alt="door position"/>
        </div>
        <span>{{ heightImage.image_description }}</span>

      </div>

    </div>
    <div class="option-step__options" style="margin-bottom: 0;" v-if="isSideRequired">
      <div class="option-step__title">Lock Side</div>
      <SideItems
        class="door__item"
        :items="currentSettings.groups.lock_side.choices.map(i => { return {key: i.name, value: i, label: i.name, image: i.image}})"
        :is_locked="items.sections[currentSection].lock_side"
        :key="'name'"
        v-model="sideValue"
        @update:modelValue="updValues(sideValue, 'lock_side'),showLockSideError = false"
      />
      <div class="form-group__error" v-if="showLockSideError">Lock side is required</div>
      <div class="option-step__note text_center">Please note: Looking from the outside</div>
    </div>

    <CylinderModal v-if="showCylinderModal"
      v-bind:cylMode="cylMode"
      v-model="showCylinderModal"
      @goToOption="goToCylinder"
      @remove="removeCylinder"
      @keepIt="keepIt"
      @close="showModal(false)"
    />
  </div>
</template>

<script>
import {ref, computed, reactive} from 'vue'
import RadioGroupItems from '../../../components/form/RadioGroupItems.vue'
import SideItems from './lock/Side.vue'
import {useOptionLogic} from "../../../composables/estimate/create"
import Checkbox from '../../form/Checkbox.vue'
import CylinderModal from './lock/CylinderModal.vue'
import SelectDropdown from '../../elements/SelectDropdown.vue'
import InputGroup from '../../form/InputGroup'
import settingsHelper from '../../../services/user.service'
import SelectColour from '../../../components/elements/SelectColor'

export default ({
  props: ['items', 'currentSection', 'isQuoting', 'error', 'currentSettings', 'selection', 'allData'],
  components: {
    RadioGroupItems,
    SideItems,
    Checkbox,
    CylinderModal,
    SelectDropdown,
    InputGroup,
    SelectColour

  },
  setup(props, {emit}) {
    const typeOptions = ref(null)
    const typeValue = ref({})

    const heightOptions = ref(null)
    const heightValue = ref({})
    const lockHeight = ref(null)
    const isAdjustTripleLockPrints = ref(null)
    const isFlipDoor = ref(null)

    const sideOptions = ref(null)
    const sideValue = ref(null)
    const sidesLocked = ref(false)

    const optionLogic = useOptionLogic(props)
    const valueKey = computed(() => optionLogic.currentKeyForValues.value)
    const saveDefaultHeight = ref(false)
    const showLockSideError = ref(false)

    const lockHasCylinderOriginal = ref(getLockHasCylinder())
    const cylinderExistsOriginal = ref(getCylinderExists())

    const showCylinderModal = ref(false)
    const cylinderSorted = ref(false)

    const cylinderModalType = ref(null)

    const saveDefaultHeightOptions = [
      {id: 20, name: 'Hinged Doors', selected: true},
      {id: 21, name: 'Sliding Doors'},
      {id: [20, 21], name: 'Hinged & Sliding Doors'}
    ];
    const selectedDefaultHeightFor = ref({id: 20, name: 'Hinged Doors', selected: true})

    const inputs = reactive({
      height: {label: 'Lock height', disabled: false, type: 'number', required: true, error: '', min: 0, max: 10},
    })

    const cylMode = computed(() => {
      return cylinderModalType.value
    })

    const isHeightAvailable = computed(() => {
      if (!typeValue.value) return false
      let value = typeValue.value?.is_height_available

      if (value === undefined) value = true
      return value
    })

    const isSideRequired = computed(() => {
      let isRequired = true
      // typeOptions.value.forEach((i) => {
        if (typeValue.value.hasOwnProperty('is_side_available') && typeValue.value.is_side_available === false)
          isRequired = false
      // })
      return isRequired
    })

    function validateHeight() {

      if (!isHeightAvailable.value) {
        inputs.height.error = ''
        return
      }

      const e = props.currentSettings.settings.height

      let error = '';

      if (!e) {
        error = 'Lock height is required'
      } else {

        const item = heightValue.value

        if (e < item.calculation.with.min) {
          error = 'Minimum lock height is ' + item.calculation.with.min
        }

        const section = props.selection[props.currentSection]
        const maxHeight = section.drop - item.calculation.with.dropMinus

        if (e > maxHeight) {
          error = 'Maximum lock height is ' + maxHeight
        }
      }
      inputs.height.error = error
    }

    const heightImage = computed(() => {
      const item = heightValue.value
      return item
    })

    function setColour(color) {
      optionLogic.selectColor(color.id, typeValue.value.id, 'default')
      typeValue.value.colour = color.id
      emit('updateOptionColour')
    }

    function checkHeightAvailable() {

      //check height available
      let value = typeValue.value.is_height_available

      if (value === undefined) value = true

      const currentStatus = heightOptions.value[0].disabled
      if (!value === currentStatus) return

      //set disabled status items
      heightOptions.value.forEach(el => {
        el.disabled = !value
      })
      if (value === false) heightValue.value = null
      else optionLogic.getData1(heightOptions, heightValue, 'lock_height')
    }

    function getLockHasCylinder(){
      let currentSelectionHasCylinder = false
      props.selection.forEach((s, i) => {
        const option = s.options[props.items.optionCode]

        const lockType = option.option_settings.groups.default.choices.find(c => {
          return c.default
        })
        if (!lockType.no_cylinder)
          currentSelectionHasCylinder = true
      })
      return currentSelectionHasCylinder
    }

    function getCylinderExists(){
      let exists = false
      props.items.sections.forEach((s, i) => {
        const option = s.options['CYL']
        if (option) {
          const cylinderTypeNoLock = option?.option_settings.groups.default.choices.find(el => el.default)
          if (cylinderTypeNoLock && cylinderTypeNoLock.name.toLowerCase() != 'no cylinder')
            exists = true
        }
      })
      return exists
    }

    function checkCylinderAvailable() {
      let needModal = false

      const lockHasCylinder = getLockHasCylinder()
      const cylinderExists = getCylinderExists()
      if ((lockHasCylinder != lockHasCylinderOriginal.value || cylinderExists != cylinderExistsOriginal.value)
       && lockHasCylinder != cylinderExists) {
        needModal = true
        cylinderModalType.value = cylinderExists ? 'remove' : 'add'
        showModal(true)

      }

      return !needModal
    }

    function flipDoor(v){

      props.selection.forEach((s) => {
        const option = s.options[props.items.optionCode]
        option.option_settings.settings.flip_door = v
      })
    }

    function validate(excludeValidation = false) {
      let isValid = true

      props.selection.forEach((s, i) => {
        const option = s.options[props.items.optionCode]
        s.hasErrors = false
        const lockType = option.option_settings.groups.default.choices.find(c => {return c.default})
        if (!lockType) {
          s.hasErrors = true
          return
        }
        if (!lockType.hasOwnProperty('is_side_available') || lockType.is_side_available != false){
          const lockSide = option.option_settings.groups.lock_side.choices.find(c => {return c.default})
          if (!lockSide && s.id == 1) {
            s.hasErrors = true
            return
          }
        }

        if (!lockType.hasOwnProperty('is_height_available') || lockType.is_height_available){
          const lockHeightChoice = option.option_settings.groups.lock_height.choices.find(c => {return c.default})
          if (!lockHeightChoice) {
            s.hasErrors = true
            return
          }
          const lockHeight = option.option_settings.settings?.height
          if (!lockHeight ||
            lockHeight < (lockHeightChoice.calculation.with.min || 0) ||
            lockHeight > (s.drop - lockHeightChoice.calculation.with.dropMinus)) {
            s.hasErrors = true
            return
          }
        }
      })

      // To prevent user selection when there are multiple sections at layout
      if(excludeValidation){
        showLockSideError.value = false
        inputs.height.error = ""
        return isValid
      }

      validateHeight()
      if (inputs.height.error) isValid = false

      if (isHeightAvailable.value && props.currentSection === 0 && !sideValue.value) isValid = false

      showLockSideError.value = isHeightAvailable.value && !sideValue.value

      return isValid
    }

    function submit(){
      if (saveDefaultHeight.value === true){
        saveHeightSettings();
      }

      if (!cylinderSorted.value) {
        cylinderSorted.value = checkCylinderAvailable()

        if (!cylinderSorted.value)
          return false
      }


      return true
    }

    function saveHeightSettings(){
      settingsHelper.saveLockHeightSettings({
        setting: 'lock_height',
        types: selectedDefaultHeightFor.value.id,
        value: heightValue.value.name
      })
    }


    function showModal(value) {
      showCylinderModal.value = value
      if (!value) emit('needShowModal', false)
    }

    function removeCylinder() {

      props.items.sections.forEach((s, k) => {
        const cyl = s.options['CYL']
        if (cyl){
          cyl.option_settings.groups.default.choices.forEach(c => {
            if (c.name.toLowerCase() == 'no cylinder') {
              c.default = true
            }
            else
              delete c.default
          })
          emit('setOption', k, 'CYL', cyl)
        }
      })

      cylinderSorted.value = true
      emit('nextOption', 'next')
      showModal(false)
    }

    function goToCylinder() {
      cylinderSorted.value = true

      props.items.sections.forEach((s, k) => {
        const curr = props.selection.find( i => i.id == s.id)
        if (curr){
          s.options['LOCK'] = curr.options['LOCK']
          // emit('setOption', k, 'LOCK', curr.options['LOCK'])
        }
      })

      emit('nextOption', 'cylinder')
      showModal(false)
    }

    function keepIt() {
      cylinderSorted.value = true
      emit('nextOption', 'next')
      showModal(false)
    }

    getData()

    function getData() {
      optionLogic.getData1(typeOptions, typeValue, 'default')
      optionLogic.getData1(heightOptions, heightValue, 'lock_height')
      optionLogic.getData1(sideOptions, sideValue, 'lock_side')
      checkHeightAvailable()
      sidesLocked.value = props.currentSettings.groups.lock_side.locked || false

      if (!props.currentSettings.settings)
        props.currentSettings.settings = {height: null, adjust_triple_lock : false}
      else {
        isAdjustTripleLockPrints.value = props.currentSettings.settings?.adjust_triple_lock
        isFlipDoor.value = props.currentSettings.settings?.flip_door ?? false
        lockHeight.value = props.currentSettings.settings?.height
      }
    }

    function updValues(selectedId, groupName) {
      optionLogic.selectItem(selectedId, groupName)
    }

    return {
      cylMode,
      optionLogic,
      sideOptions,
      sideValue,
      typeOptions,
      typeValue,
      heightOptions,
      heightValue,
      isAdjustTripleLockPrints,
      isFlipDoor,
      updValues,
      getData,
      heightImage,
      valueKey,
      checkHeightAvailable,
      showCylinderModal,
      showModal,
      goToCylinder,
      removeCylinder,
      keepIt,
      sidesLocked,
      saveDefaultHeight,
      saveDefaultHeightOptions,
      selectedDefaultHeightFor,
      isHeightAvailable,
      inputs,
      validateHeight,
      lockHeight,
      flipDoor,
      validate,
      isSideRequired,
      submit,
      setColour,
      showLockSideError

    }

  },
})
</script>


<style scoped lang="scss">
.door__item {
  flex-direction: column;

  ::v-deep .radio-group__item {
    margin-bottom: 10px;
  }
}

.lock {
  display: flex;
  width: 100%;
  flex-direction: row;

  &__options {
    margin-bottom: 40px;
    min-width: 33%;
  }
}

.lock-height {
  &__image {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 300px;
    min-height: 320px;

    .lock__image {
      height: 100%;

      &-wrapper {
        max-width: 100%;
        height: 305px;
      }
    }

    span {
      width: 250px;
      display: inline-block;
      text-align: left;
      // margin-top: 10px;
    }
  }
}

.form-group__error {
  font-size: 14px;
  line-height: 140%;
  color: #DB5151;
  text-align: center;
}

</style>
