<template>
    <div class="insert">
        <div class="insert__actions">
            <InputGroup
                class="location__input"
                :data="{ label: '', placeholder: 'Type to Search', type: 'search', required: true, error: ''}"
                v-model="searchQuery"
            />
        </div>
        <div class="insert__items-container" :class="{'addspace' : values}">

            <div class="insert__items" v-if="options">
                <div class="insert__item"
                     :class="{ disabled: item.disabled }"
                     v-for="item in searchResult" :key="item.key"
                     @click="[updValues()]"
                >
                    <div class="item__img">
                        <div class="img__bg"></div>
                        <img :src="item.image" loading="lazy" alt="inset example">
                    </div>
                    <div class="item__info">
                        <span>Width: {{`${getSizeValue('width', item)}`}}</span>
                        <span>Drop: {{`${getSizeValue('drop', item)}`}}</span>
                    </div>
                    <div class="item__selected">
                        <RadioGroup
                            :disabled="item.disabled"
                            :data="{label: item.label, key: item.id, value: item}"
                            :key="'id'"
                            v-model="values"
                            @update:modelValue="updValues(values)"
                        />
                    </div>
                </div>
            </div>
        </div>
      <div class="insert__colour"  v-if="values">

        <SelectColor
          :colour="values.colour"
          :colourGroup="colorGroup"
          :frameColour="frameColour"
          :sameAsFrame="values.same_as_frame_colour"
          @submit="selectColor"/>
      </div>
    </div>
</template>

<script>
import { ref, computed } from 'vue'

import InputGroup from '../../form/InputGroup'
import RadioGroup from '../../form/RadioGroup'
import InputSize from '../../form/InputSize'
import { useOptionLogic } from "../../../composables/estimate/create"
import { useLimitsLogic } from '../../../composables/estimate/limits'
import SelectColor from '../../elements/SelectColor.vue'
// import SelectColor from '../../../'


export default ({
    props: ['items', 'currentSection', 'allData', 'currentSettings', 'selection', 'frameColour'],
    components: {
        InputGroup,
        RadioGroup,
        InputSize,
        SelectColor,
    },
    setup(props, { emit }) {
        const options = ref(null)
        const values = ref({})
        const optionLogic  = useOptionLogic(props)
         //check disabled items
        const limitsLogic = useLimitsLogic(props)
        const insertColor = computed(() => {
          return  options.value.find(el => el.value == Object.values(values.value)[0]).colour
        })
        getData()
        function getData() {
            optionLogic.getData1(options, values)
            options.value = options.value.map(el => {
                const disabled = limitsLogic.getDisabledValue(el)
                return {
                    ...el,
                    disabled,
                }
            })
            values.value = options.value.find(i => i.default)
        }

        function updValues(val) {
            emit('error','')
            optionLogic.selectItem(val)
          // emit('updated')
        }

        const searchResult = computed(() => {
            const search = searchQuery.value.split('').join('').toLowerCase()
            if(search) return options.value.filter(el => el.name.split('').join('').toLowerCase().includes(search))
            return options.value
        })

        const searchQuery = ref('')

        const colorGroup = computed(() =>  {
          return props.currentSettings.groups.default.colour_group
        })


        function selectColor(colour) {
            const key = optionLogic.currentKeyForValues.value
            const currentItem = values.value[key]
            values.value.colour = colour.id
            values.value.same_as_frame_colour = colour.is_same_as_frame ?? false

            const section = props.selection[props.currentSection]
            const settings = section.options['INS'].option_settings

            settings.groups.default.choices.forEach((value) => {
              value.colour = colour.id
              value.same_as_frame_colour = colour.is_same_as_frame ?? false
            })

            emit('updateOptionColour')
        }

        function getSizeValue(type, item) {
            const min_value = item[`${type}_min`]
            const max_value = item[`${type}_max`]

            return `${min_value}-${max_value}mm`
        }

        function validate() {
          let isValid = values.value && values.value.id
          if(!isValid) {
            emit('error','Please select the insert')
          }
          return isValid
        }

        return {
          insertColor,
            searchQuery,
            options,
            values,
            optionLogic,
            searchResult,
            updValues,
            getSizeValue,
            colorGroup,
            selectColor,
          validate,
        }
    },
})
</script>


<style scoped lang="scss">
.insert {
    height: 100%;
    //position: relative;
    &__actions {

        max-width: 100%;
        width: 100%;

        @media (min-width: 640px) {
            width: 400px;
        }
    }
    &__items {
        display: flex;
        flex-wrap: wrap;
        width: calc(100% + 80px);
        transform: translateX(-40px);
      padding-bottom: 90px;
        &-container {
            height: calc(100% - 60px);
            overflow-y: auto;
            overflow-x: hidden;
          &.addspace{
            padding-bottom: 80px;
          }
        }
    }
    &__item {
        margin: 20px 40px 0;
        min-width: 105px;
        cursor: pointer;
        text-align: -webkit-center;

        &.disabled {
            .item {
                &__img {
                    opacity: 0.4;
                }
                &__info {
                    color: #DB5151;
                }
            }
        }
    }
  &__colour{
    position: sticky;
    bottom: 0;
    background-color: white;
    width: 100%;
    padding: 5px;
    margin-bottom: 15px;
    border-top: 1px solid;
  }

}
.item {
    &__img {
        display: flex;
        position: relative;
        max-width: 75px;
        img {
            max-height: 150px;
            position: relative;
            z-index: 10;
        }
    }
    &__info {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 5px 0;
        span {
            display: block;
            margin-top: 5px;
            font-size: 12px;
            &::first-of-type {
                margin-top: 0;
            }
        }
    }
  &__selected{
    display: flex;
    flex-direction: column;
    align-items: center;

    .radio-group{
      margin-right: 0px;
    }

  }
}
.options {
    display: flex;
    flex-wrap: wrap;
    &__item {
        width: 400px;
        margin-right: 0;
        &:last-of-type {
            margin-right: 0;
        }
    }

}

::v-deep .radio-group__input:checked, ::v-deep .radio-group__input:not(:checked) {
    label {
        padding-left: 25px;
    }
}
.img {
    &__bg {
        background: var(--PrimaryColour);
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        right: 0;
    }
}
.select-color {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 0px;
    padding-left: 10px;
    align-items: flex-start;
    box-shadow: 0 -5px 5px -5px rgba(0, 0, 0, 0.25);
    z-index: 1;
    width: 100%;
    background: #fff;
    span {
        margin-bottom: 5px;
    }
}
</style>
