<template>
    <div class="select" v-if="currentSettings.groups.default"  :key="`${optionLogic.currentKeyForValues.value}_${values[optionLogic.currentKeyForValues.value]}`" >

      <RadioGroupItems
        class="select__item"
        :items="currentSettings.groups.default.choices.map(i => { return {
          key: i.name, label: i.name, value: i, id: i.name, help_topic_url: i.help_topic_url}
        })"
        :key="'id'"
        :vertical="vertical"
        v-model="selectedValue"
        @update:modelValue="updValues"/>



<!--        </div>-->
    </div>
</template>

<script>
import {ref, computed} from 'vue'
import { useOptionLogic } from "../../../composables/estimate/create"
import HelpTopic from '../../elements/HelpTopic.vue'
import RadioGroup from '../../form/RadioGroup.vue'
import RadioGroupItems from '../../form/RadioGroupItems.vue'

export default({
    props: ['items', 'currentSection', 'name', 'currentSettings', 'selection', 'vertical'],
    components: {
        HelpTopic,
        RadioGroup,
      RadioGroupItems
    },
    setup(props, {emit}) {
        const options = ref(null)
        const values = ref({})
        const optionLogic  = useOptionLogic(props)

        const selectedValue = ref ({})
        getData()
        function getData() {
            optionLogic.getData1(options, selectedValue)
        }

        function updValues() {
            optionLogic.selectItem(selectedValue.value)
            emit('updated', selectedValue.value)
        }

        return {
            options,
            values,
            optionLogic,
            getData,
            updValues,
            selectedValue
        }

    },
})
</script>


<style scoped lang="scss">
.select {
    display: block;
    align-items: flex-start;
    flex-wrap: wrap;
    &__item {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        min-width: 240px;
        margin-right: 15px;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(273px, 1fr));
    }
    &.cylinder, &.wheels {
        flex-direction: column;
        ::v-deep .radio-group__items {
            flex-direction: column;
        }
    }
    ::v-deep .radio-group {
        min-width: auto;
        margin-right: 5px;
    }
}
</style>
