<template>
    <div class="option-step">
        <div class="option-step__options" v-if="currentSettings.groups.bug_strip && currentSettings.groups.bug_strip.choices.length">
            <div class="option-step__title">Bug Strip</div>
            <RadioGroupItems
                class="option-step__radio-group"
                :items="currentSettings.groups.bug_strip.choices.map(i => { return {key: i.name, label: i.name, value: i, id: i.name}})"
                :key="'id'"
                v-model="bugStripValues"
                @update:modelValue="updValues(bugStripValues, 'bug_strip')"
            />
            <div class="option-step__color" v-if="bugStripValues && bugStripValues.name != 'No Bug Strip'" >
                <span>Colour:</span>
                <SelectColor
                  :colour="bugStripValues.colour"
                  :sameAsFrame="bugStripValues.same_as_frame_colour"
                  :colourGroup="bugStripValues.colour_group"
                  :frameColour="frameColour"
                  @submit="selectColor($event, bugStripValues, 'bug_strip')"/>
            </div>
        </div>
        <div class="option-step__options" v-if="currentSettings.groups.seal && currentSettings.groups.seal.choices.length">
            <div class="option-step__title">Seal</div>
            <RadioGroupItems
                class="option-step__radio-group"
                :items="currentSettings.groups.seal.choices.map(i => { return {key: i.name, label: i.name, value: i}})"
                :key="'id'"
                v-model="sealValues"
                @update:modelValue="updValues(sealValues, 'seal')"
            />
            <div class="option-step__color" v-if="sealValues && sealValues.name != 'No Seal'" >
                <span>Colour:</span>
                <SelectColor
                  :colour="sealValues.colour"
                  :sameAsFrame="sealValues.same_as_frame_colour"
                  :colourGroup="sealValues.colour_group"
                  :frameColour="frameColour"
                  @submit="selectColor($event, sealValues, 'seal')"/>
            </div>
        </div>
        <div class="option-step__options" v-if="currentSettings.groups.interlock && currentSettings.groups.interlock.choices.length">
          <div class="option-step__title">Interlock</div>
          <div class="other-step__item"
               v-for="(item, index) in currentSettings.groups.interlock.choices"
               :key="index"
          >
            <Checkbox
              :data="{key: item.name, label: item.name, ...item}"
              v-model="item.selected"
              @update:modelValue="updatePrice"
            />
            <div class="option-step__color" v-if="item.selected">
              <span>Colour:</span>
              <SelectColor
                :colour="item.colour"
                :sameAsFrame="item.same_as_frame_colour"
                :colourGroup="item.colour_group"
                :frameColour="frameColour"
                @submit="selectColor($event, item, 'interlock')" />
            </div>
          </div>
        </div>

        <div class="option-step__options" v-if="currentSettings.groups.other && currentSettings.groups.other.choices.length">
            <div class="option-step__title">Other</div>
            <div class="other-step__item"
                v-for="(item, index) in currentSettings.groups.other.choices"
                :key="index"
            >
                <Checkbox
                    :data="{key: item.name, label: item.name, ...item}"
                    v-model="item.selected"
                    @update:modelValue="updatePrice"
                />
                <div class="option-step__color" v-if="item.selected">
                    <span>Colour:</span>
                  <SelectColor
                    :key="item.name"
                    :colour="item.colour"
                    :sameAsFrame="item.same_as_frame_colour"
                    :colourGroup="item.colour_group"
                    :frameColour="frameColour"
                    @submit="selectColor($event, item, 'other')" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Checkbox from '../../form/Checkbox.vue'
import RadioGroupItems from '../../form/RadioGroupItems.vue'
import { useOptionLogic } from "../../../composables/estimate/create"
import SelectColor from '../../elements/SelectColor.vue'
import { ref, computed } from 'vue'

export default  ({
    props: ['items', 'currentSection', 'allData', 'currentSettings', 'selection', 'frameColour'],
    components: {
        Checkbox,
        RadioGroupItems,
        SelectColor,
    },
    setup(props, { emit }) {
        const optionLogic  = useOptionLogic(props)

        const bugStripOptions = ref(null)
        const bugStripValues = ref({})
        // const bugStripColorGroup = ref({})
        const bugStripColorGroup = computed(() => optionLogic.getColorGroup('bug_strip'))

        const sealOptions = ref(null)
        const sealValues = ref({})
        // const sealColorGroup = ref({})
        const sealColorGroup = computed(() => optionLogic.getColorGroup('seal'))

         const interlockOptions = ref(null)
        const interlockValues = ref({})
        // const sealColorGroup = ref({})
        const interlockColorGroup = computed(() => optionLogic.getColorGroup('interlock'))

        const otherOptions = ref(null)
        const otherValues = ref({})
        // const otherColorGroup = ref({})

        getData()
        function getData() {
          if (props.currentSettings.groups.bug_strip)
            optionLogic.getData1(bugStripOptions, bugStripValues, 'bug_strip')
          if (props.currentSettings.groups.seal)
            optionLogic.getData1(sealOptions, sealValues, 'seal')
          if (props.currentSettings.groups.other)
            optionLogic.getData1(otherOptions, otherValues, 'other')
          if (props.currentSettings.groups.interlock)
            optionLogic.getData1(interlockOptions, interlockValues, 'interlock')

        }

        function selectColor(colour, values, groupName) {
          if (groupName != 'other' && groupName != 'interlock') {
            optionLogic.selectColor(colour.id, values, groupName)
          }
          values.colour = colour.id
          values.same_as_frame_colour = colour.is_same_as_frame

          emit('updateOptionColour')
        }

        function updValues(values, groupName) {
            optionLogic.selectItem(values, groupName)
        }

        function updatePrice(){
          optionLogic.getPrice()
        }

        return {
            bugStripOptions,
            sealOptions,
            otherOptions,
            bugStripValues,
            sealValues,
            otherValues,
            optionLogic,
            bugStripColorGroup,
            sealColorGroup,
            updValues,
            selectColor,
            interlockValues,
            interlockOptions,
            interlockColorGroup,
          updatePrice,
          getData
        }
    },
})
</script>


<style scoped lang="scss">
.option-step {
    flex-direction: row;
    padding: 0 5px;
    &__radio-group {
        flex-direction: column;
        :deep(.radio-group__item) {
            margin-right: 5px;
            margin-bottom: 10px;
          width: 100%;

          .radio-group{
            padding: 0px;

            label{
              white-space: unset;
            }
          }
        }
    }
}
.other-step__item {
    :deep(.form-group) {
        margin-bottom: 10px;
    }
}
</style>
