<template>
    <OptionOneRadioGroup
        :items="items"
        :currentSection="currentSection"
        :currentSettings="currentSettings"
        :selection="selection"
        :name="name"
    />
  <!--        @updated="checkLockAvailable"-->
  <template v-if="showCylinderModal">
    <CylinderModal
        v-model="showCylinderModal"
        :desc="`Selected type of cyliner does not include a
                lock but lock is selected.
                Do you want remove the lock from  this item?
                Please select one of the following options`"
        @goToOption="goToLock"
        @keepIt="keepIt"
        @close="showModal(false)"
    />
  </template>
</template>

<script>
import OptionOneRadioGroup from './OptionOneRadioGroup.vue'
import CylinderModal from './lock/CylinderModal.vue'
import { ref } from 'vue'

export default ({
    props: ['items', 'currentSection', 'name', 'currentSettings', 'selection'],
    components: {
        OptionOneRadioGroup,
        CylinderModal,
    },
    setup(props, { emit }) {

        const showCylinderModal = ref(false)

        function checkLockAvailable() {
            const optionCode = props.items.optionCode
            const options = props.items.sections[props.currentSection].options
            const currentOption = options[optionCode]
            const lockOption = options['Lock']

            const lockOptionTypeGroup = lockOption.option_settings.groups.find(group => group.name == 'default')
            const lockTypeNoCylinder = lockOptionTypeGroup.choices.find(el => el.default && el.no_cylinder)

            const choices = currentOption.option_settings.groups[0].choices

            const noCylinder = choices.find(el => el.default && el.id == 3)
            if(!lockTypeNoCylinder) emit('needShowModal', noCylinder)
        }

        function showModal(value) {
            showCylinderModal.value = value
        }

        function goToLock() {
            emit('nextOption', 'lock')
            showModal(false)
        }

        function keepIt() {
            emit('nextOption', 'next')
            showModal(false)
        }

        return {
            checkLockAvailable,
            showCylinderModal,
            showModal,
            goToLock,
            keepIt
        }
    },
})
</script>
