<template>
    <PortSetsForm
      :items="items"
      :currentSection="currentSection"
      :selectOptions="selectOptions"
      :currentSettings="currentSettings"
      :selection="selection"
      name="Flyscreen Port Set"
      @error="$emit('error', $event)"/>
</template>

<script>
import PortSetsForm from './PortSetsForm.vue'

export default({
    props: ['items', 'currentSection', 'currentSettings', 'selection'],
    components: {
        PortSetsForm
    },
    setup() {
        const selectOptions = [
            { value: 0, label: '0°' },
            { value: 90, label: '90°' },
            { value: 180, label: '180°' },
            { value: 270, label: '270°' },
        ]


        return {
            selectOptions
        }
    },
})
</script>
