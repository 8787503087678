<template>
    <div class="items" v-if="currentSettings.choices">
      <div class="tw-grid tw-grid-cols-12 tw-items-center tw-justify-center tw-text-sm">
        <div class="tw-col-span-2 md:tw-col-span-1"></div>
        <div class="tw-col-span-4 tw-text-center">{{ isDropIsTheSizeToEnterFirst? 'Drop (mm)': 'Width (mm)' }}</div>
        <div class="tw-col-span-4 tw-text-center">{{ isDropIsTheSizeToEnterFirst? 'Width (mm)': 'Drop (mm)' }}</div>
        <div v-if="selectOptions" class="tw-col-span-2 md:tw-col-span-3">Rotation</div>
      </div>
        <div class="tw-grid tw-grid-cols-12 tw-gap-y-3 tw-items-center tw-mb-4">
          <template class="item" v-for="(item, index) in currentSettings.choices" :key="`${index}_${currentSection}`">
              <div class="tw-col-span-2 md:tw-col-span-1 tw-flex tw-items-center" :class="`tw-row-start-${index+1}`">
                <span class="item__num">{{ index + 1}}.</span>
                <Checkbox
                    class="item__checkbox"
                    :data="{ key: `checkbox-${index}`}"
                    :disabled="item.required"
                    v-model="item.selected"
                    @update:modelValue="updValues(index)"
                />
              </div>
              <div class="tw-col-span-4 tw-flex" :class="[isDropIsTheSizeToEnterFirst? 'tw-col-start-7 tw-col-end-11 md:tw-col-start-6 md:tw-col-end-10': '', `tw-row-start-${index+1}`]">
                <InputSize
                    :class="{'change_order_2':isDropIsTheSizeToEnterFirst}"
                    class="item__input"
                    :data="{ type: 'number', tabindex : isDropIsTheSizeToEnterFirst ? parseInt(index) + 3 : parseInt(index) + 2 }"
                    :item="item"
                    :disabled="!item.selected"
                    v-model="item.width"
                    @update:modelValue="updValues(index)"
                />
              </div>
              <div class="tw-col-span-4 tw-flex" :class="[isDropIsTheSizeToEnterFirst? 'tw-col-start-3 tw-col-end-7 md:tw-col-start-2 md:tw-col-end-6': '',  `tw-row-start-${index+1}`]">
                <InputSize
                    :class="{'change_order_1':isDropIsTheSizeToEnterFirst}"
                    class="item__input"
                    :data="{ type: 'number', tabindex : isDropIsTheSizeToEnterFirst ? parseInt(index) + 2 : parseInt(index) + 3 }"
                    :disabled="!item.selected"
                    :item="item"
                    v-model="item.height"
                    @update:modelValue="updValues(index)"
                />
            </div>
            <div class="tw-col-span-2 md:tw-col-span-3" :class="`tw-row-start-${index+1}`">
              <select
                class="item__select change_order_3"
                v-model="item.rotation"
                v-if="selectOptions"
                :disabled="!item.selected"
                @update:modelValue="updValues(index)">
                  <option :value="item.value" v-for="item in selectOptions" :key="item.value">{{ item.label }}</option>
              </select>
            </div>
          </template>
        </div>
        <div class="items__note">
            <div>Please note: Looking from outside</div>
            <div>Position is measured from bottom left of the screen to centre of {{name}}</div>
        </div>
        <div class="option-step__color" v-if="currentSettings.colour" >
          <span>Colour:</span>
          <SelectColor
            :colour="currentSettings.colour"
            :colourGroup="currentSettings.colour_group"
            @submit="selectColor($event)"/>
        </div>
    </div>
</template>

<script>
import InputSize from '../../form/InputSize.vue'
import Checkbox from '../../form/Checkbox.vue'
import { useOptionLogic } from "../../../composables/estimate/create"
import { ref, computed } from 'vue'
import {isDropIsTheSizeToEnter} from "../../../helpers/portalSpecifications";
import SelectColor from '../../elements/SelectColor.vue'

export default({
    props: ['items', 'currentSection', 'selectOptions', 'currentSettings', 'selection', 'name', 'showColour'],
    components: {
        InputSize,
        Checkbox,
      SelectColor
    },
    setup(props, { emit }) {
        const optionLogic  = useOptionLogic(props)

        const optionName = ref(props.items.optionName)
        const optionCode = ref(props.items.optionCode)

         const isDropIsTheSizeToEnterFirst = props.isQuoting ? false : ref(isDropIsTheSizeToEnter())

        const limits = computed(() => props.currentSettings.limits)

        function getData() {
          props.currentSettings.choices = props.currentSettings.choices.map((el) => {
                return {
                    error: false,
                    ...el,
                }
            })
        }
        getData()

        function selectColor(colour) {
          props.currentSettings.colour = colour.id

          emit('updateOptionColour')
        }


        function updValues(index) {
            validation()

            const input = props.currentSettings.choices[index]
            if(input.selected) {
                const currentItem = props.currentSettings.choices[index]
                Object.assign(currentItem, input)
            }else{
              props.currentSettings.choices[index].width=
              props.currentSettings.choices[index].drop=
              props.currentSettings.choices[index].rotation = null
            }
            optionLogic.getPrice()
        }

        function validation() {
          const maxDrop = parseInt(limits.value.height)
          const maxWidth = parseInt(limits.value.width)
          const {drop, width} = props.items.sections[props.currentSection]

          props.selection.forEach((s, i) => {
            const option = s.options[props.items.optionCode]
            s.hasErrors = false

            const choices = option.option_settings.choices

            choices.forEach(c => {
              if (c.selected){
                if (!c.height || !c.width){
                  s.hasErrors = true
                }else{
                  if(parseInt(c.height) < maxDrop ||
                    parseInt(c.height) > parseInt(drop) - maxDrop ||
                    parseInt(c.width) < maxWidth ||
                    parseInt(c.width) > parseInt(width) - maxWidth) {
                    s.hasErrors = true
                  }
                }
              }
            })
          })

          const inputs = props.currentSettings.choices

          let errorMsg = ''

            inputs.forEach(el => {
                if(el.selected) {
                  let error = false
                  if (!el.height || !el.width){
                    errorMsg=props.selectOptions? 'Width, Drop and Rotation fields are required': 'Width and Drop fields are required'
                    error = true
                  }else {
                    if (parseInt(el.height) < maxDrop || parseInt(el.height) > parseInt(drop) - maxDrop
                      || parseInt(el.width) < maxWidth || parseInt(el.width) > parseInt(width) - maxWidth){
                      errorMsg = `Error: min distance to horizontal edges is ${maxWidth}mm or to vertical edges is ${maxDrop}mm`
                      error = true
                    }
                  }
                  if (error !== el.error) el.error = error
                }
            })
            const errorItems = inputs.filter(el => el.error)
            const emitData = errorItems.length ? errorMsg : ''
            emit('error', emitData)
        }

        return {
            getData,
            updValues,
            isDropIsTheSizeToEnterFirst,
          selectColor
        }
    },
})
</script>

<style scoped lang="scss">

::v-deep .input-size {
  width: 100%;
}
::v-deep .item__input {
  @media(max-width: 768px) {
    margin: 0!important;
  }
}
::v-deep .input-size__input {
  width: 100%;
}
.item {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    &__checkbox {
        margin-bottom: 0;
        width: auto;
    }
    &__num {
        display: block;
        margin-right: 10px;
        font-size: 20px;
    }
    &__input {
        margin-right: 20px;
    }
    &__select {
        width: 60px;
        text-align: right;
        border: 1px solid var(--PrimaryButtonColour);
        // box-shadow: 0 1px 2px rgba(0 0 0 / 5%), inset 0px -15px 10px -12px rgba(0 0 0 / 5%);
        height: 25px;
    }
}
.items {
    &__note {
        text-align: center;
        position: absolute;
        bottom: 0px;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
    }
}
.change_order_1{
  order: 1;
}
.change_order_2{
  order: 2;
}
.change_order_3{
  order: 3;
}

.tw-row-start-1 {
  grid-row-start: 1;
}

.tw-row-start-2 {
  grid-row-start: 2;
}

.tw-row-start-3 {
  grid-row-start: 3;
}

.tw-row-start-4 {
  grid-row-start: 4;
}

.tw-row-start-5 {
  grid-row-start: 5;
}

.tw-row-start-6 {
  grid-row-start: 6;
}

.tw-row-start-7 {
  grid-row-start: 7;
}

.tw-row-start-8 {
  grid-row-start: 8;
}

.tw-row-start-9 {
  grid-row-start: 9;
}
</style>

<style lang="scss">
.flyscreen-port-sets, .hopper-hatches {
    & + .validation_text {
        position: relative;
        width: 100%;
        text-align: left;
        display: block;
    }
}
</style>
