
<template>
    <div class="option-step" v-if="typeOptions && directionOptions && qtyOptions">
        <div class="option-step__options">
            <div class="option-step__title">Hinge Type</div>
            <RadioGroupItems
                class="option-step__item"
                :items="currentSettings.groups.hinge_type.choices.map(i => { return {label: i.name, key: i.id, value: i, id: i.id}})"
                :key="'hinge_type_id'"
                v-model="typeValue"
                @update:modelValue="updValues(typeValue, 'hinge_type')"/>
        </div>
        <div class="option-step__options">
            <div class="option-step__title">Hinge Direction</div>
            <RadioGroupItems
                class="hinge__item"
                :items="currentSettings.groups.hinge_direction.choices.map(i => { return {label: i.name, key: i.name, id: i.name, value: i}})"
                :key="'hinge_direction_id'"
                v-model="directionValue"
                @update:modelValue="updValues(directionValue, 'hinge_direction')"/>
        </div>
        <div class="option-step__options" style="margin-bottom: 0;">
            <!-- <img  class="hinge__image" :src="doorPositionImage" alt="door position"/> -->
            <div class="option-step__title">Hinge Quantity</div>
            <RadioGroupItems
                class="option-step__item"
                :items="currentSettings.groups.hinge_qty.choices.map(i => { return {label: i.name, key: i.name, value: i, id: i.name}})"
                :key="'id'"
                v-model="qtyValue"
                @update:modelValue="updValues(qtyValue, 'hinge_qty')"/>
        </div>
    </div>
</template>

<script>
import { ref, computed } from 'vue'
import RadioGroupItems from '../../form/RadioGroupItems.vue'
import { useOptionLogic } from "../../../composables/estimate/create"

export default({
    props: ['items', 'currentSection', 'currentSettings', 'selection', 'allData'],
    components: {
        RadioGroupItems,
    },
    setup(props) {
        const typeOptions = ref(null)
        const typeValue = ref({})

        const directionOptions = ref(null)
        const directionValue = ref({})

        const qtyOptions = ref(null)
        const qtyValue = ref({})

        const optionLogic = useOptionLogic(props)

        getData()
        function getData() {
            optionLogic.getData1(typeOptions, typeValue, 'hinge_type')
            optionLogic.getData1(directionOptions, directionValue, 'hinge_direction')
            optionLogic.getData1(qtyOptions, qtyValue, 'hinge_qty')

            const hngOption = props.allData.steps.filter(i => i.option_code === 'HNG')[0]
            if (!hngOption.is_set){
              if (+(props.items.sections[props.currentSection].drop) > 2250){
                let currentQty = 0
                qtyValue.value.components.map(i => {
                  currentQty += i.qty
                })
                props.currentSettings.groups.hinge_qty.choices.map(i => {
                  let choiceQty = 0
                  i.components.map(i => {
                    choiceQty += i.qty
                  })

                  i.default = choiceQty === currentQty + 1
                  if (i.default === true)
                    qtyValue.value = i
                  return i
                })
                // optionLogic.selectItem(qtyValue.value, 'hinge_qty')
              }
            }
        }
        function updValues(values, groupName) {
            optionLogic.selectItem(values, groupName)
        }

        return {
            optionLogic,
            directionOptions,
            directionValue,
            typeOptions,
            typeValue,
            qtyOptions,
            qtyValue,
            updValues,
            getData,
        }

    },
})
</script>


<style scoped lang="scss">
.door__item {
    flex-direction: column;
    ::v-deep .radio-group__item {
        margin-bottom: 10px;
    }
}

</style>
