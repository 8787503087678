<template>
    <Modal class="select-color-modal" :modelValue="show" @update:modelValue="$emit('close')">
        <Loader customClass="big" class="select-color-modal__loader" v-if="!items" />
        <Color :items="items" v-else @submit="$emit('submit', $event)"/>
    </Modal>
</template>

<script>
import Modal from '../elements/Modal.vue'
import Color from '../steps/Color.vue'
import Loader from '../elements/Loader.vue'

import { ref, computed } from 'vue'

export default ({
    props: ['items', 'show'],
    components: {
        Modal,
        Color,
        Loader,
    },
})
</script>

<style scoped lang="scss">
.estimate {
    .select-color-modal {
        top: 0;
        width: 80vw;
        ::v-deep .modal {
            max-width: 100%;
        }
    }
}
.select-color-modal {
    &.modal-wrapper.show {
        min-width: 50vw!important;
        width: 100%;
        @media(max-width: 768px) {
            min-width: 100vw;
        }
    }
    ::v-deep .modal {
      width: 80vw;
        //width : auto !important;
        max-width: 1040px;
       // width: calc(100% - 100px);
        top: 50%!important;
        height: calc(100% - 100px);
        padding: 20px!important;
    }
    ::v-deep .color {
        padding : 25px;
        height: 100%;
    }
}
</style>
